import React, {useState, useEffect} from 'react'
import {
    Checkbox,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Card,
    Chip,
    CardContent,
    Grid,
    Box,
    Typography,
    Button,
  } from '@material-ui/core';
  import { Delete, Visibility } from '@material-ui/icons';
  import LockIcon from '@mui/icons-material/Lock';
  import NavBar from "../../../components/NavBar";
import instance from '../../../globals/axios';
import ProfileHeader from '../../../components/ProfileHeader.js';
import { styled, makeStyles } from '@mui/system';
import CustomAccordian from '../../../components/CustomAccordian.js';
import { MdVisibility } from 'react-icons/md';
import { IconButton } from "@mui/material";

    export default function DocumentLocker({showTitle, showImage}) {
        const [documentData, setDocumentData] = useState({});
      
        useEffect(() => {
          instance.get('document-locker/')
            .then(response => {
              setDocumentData(response.data);
            })
            .catch(error => {
              console.error('Error fetching document data:', error);
            });
        }, []);

        const [userFiles, setUserFiles] = useState([]);
        const [loading, setLoading] = useState(true);

        useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await instance.get('upload-experience-details/');
              setUserFiles(response.data); 
            } catch (error) {
              console.error('Error fetching additional data:', error);
            } finally {
              setLoading(false);
            }
          };
      
          fetchData();
        }, []);

        const [educationData, setEducationData] = useState({});

        useEffect(() => {
            const fetchData = async () => {
              try {
                const response = await instance.get('educational-details/');
                setEducationData(response.data); 
                setLoading(false);
              } catch (error) {
                console.error('Error fetching educational data:', error);
                setLoading(false);
              }
            };

            fetchData();
          }, []);

        const [experienceData, setExperienceData] = useState({});

        useEffect(() => {
            const fetchData = async () => {
              try {
                const response = await instance.get('experience-details/');
                setExperienceData(response.data); 
                setLoading(false);
              } catch (error) {
                console.error('Error fetching experience data:', error);
                setLoading(false);
              }
            };

            fetchData();
          }, []);

          const experienceFilesMap = {};
          userFiles.forEach(file => {
            if (!experienceFilesMap[file.experience_details]) {
              experienceFilesMap[file.experience_details] = [];
            }
            experienceFilesMap[file.experience_details].push(file);
          });


        return (
        <>
        {/* <Container maxWidth= "xs"  sx={ showTitle || showTitle === undefined ? { marginTop: '80px' } : null}> */}
        <Container  style={ showTitle || showTitle === undefined ? { marginTop: '30px' } : null}>
         {!showImage && <ProfileHeader  showUploadIcon={false}  /> }

          {
            (showTitle === undefined || showTitle) && (

              <Typography variant="h6" sx={{ fontWeight: "600"}}>Document Locker</Typography>
            )
          }

            <Grid>


        <CustomAccordian title="Personal Data">
          {/* Aadhar File Box */}
          {documentData.aadhar_file && documentData.aadhar_file !== "https://iveri-docs.s3.amazonaws.com/False" && documentData.aadhar_file !== "https://iveri-docs.s3.amazonaws.com/null" ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                padding: "10px",
                marginY: 1.7,
                border: "1.5px solid #262262",
                borderRadius: "10px",
                cursor: 'pointer'
              }}
              onClick={() => window.open(documentData.aadhar_file, "_blank")}
            >
              <Typography variant="body1">
                Aadhar Card
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(documentData.aadhar_file, "_blank");
                }}
              >
                <MdVisibility color="#262262" />
              </IconButton>
            </Box>
          ) : (
            <Typography>No Aadhar file found</Typography>
          )}
         
          {/* PAN File Box */}
          {documentData.pan_file && documentData.pan_file !== "https://iveri-docs.s3.amazonaws.com/False" ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                padding: "10px",
                marginY: 1.7,
                border: "1.5px solid #262262",
                borderRadius: "10px",
                cursor: 'pointer'
              }}
              onClick={() => window.open(documentData.pan_file, "_blank")}
            >
              <Typography variant="body1">
                PAN Card
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(documentData.pan_file, "_blank");
                }}
              >
                <MdVisibility color="#262262" />
              </IconButton>
            </Box>
          ) : (
            <Typography>No PAN file found</Typography>
          )}
         </CustomAccordian>


{documentData.languages && documentData.languages.length > 0 && (
  <CustomAccordian title="Languages">
    {documentData.languages.map((language) => (
      <Box key={language.id}>
        {language.file ? (
          <Box
            display="flex !important"
            alignItems="center !important"
            justifyContent="space-between !important"
            sx={{ padding: "1px 6px !important", my: 1.7, border: "1.5px solid #262262", borderRadius: '5px' }}
            onClick={() => window.open(language.file, "_blank")}
          >
            <Typography variant="body1" sx={{ flex: 1 }}>
              {language.languageName}
            </Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                window.open(language.file, "_blank");
              }}
            >
              <MdVisibility color="#262262" />
            </IconButton>
          </Box>
        ) : (
          <Typography>No file found</Typography>
        )}
      </Box>
    ))}
  </CustomAccordian>
)}





            {educationData && educationData.length > 0 && (
              <CustomAccordian title="Educational">
             {educationData.map((educationEntry) => (
               <React.Fragment key={educationEntry.id}>
                 {/* Awards File Box */}
                 {educationEntry.awards_file && (
                   <Box
                     display="flex"
                     alignItems="center"
                     justifyContent="space-between"
                     sx={{ padding: "1px 6px !important", cursor: 'pointer', border: "1.5px solid #262262", borderRadius: '5px' }}
                     marginY={1.5}
                     onClick={() => window.open(educationEntry.awards_file)}
                   >
                     <Typography variant="body1">
                       Awards File
                     </Typography>
                     <IconButton onClick={() => window.open(educationEntry.awards_file)}>
                       <MdVisibility color="#262262"/>
                     </IconButton>
                   </Box>
                 )}
           
                 {/* Extra Curricular File Box */}
                 {educationEntry.extra_curricular_file && (
                   <Box
                     display="flex"
                     alignItems="center"
                     justifyContent="space-between"
                     sx={{ padding: "1px 6px !important", cursor: 'pointer', border: "1.5px solid #262262", borderRadius: '5px' }}
                     marginY={1.5}
                     onClick={() => window.open(educationEntry.extra_curricular_file)}
                   >
                     <Typography variant="body1">
                       Extra Curricular File
                     </Typography>
                     <IconButton onClick={() => window.open(educationEntry.extra_curricular_file)}>
                       <MdVisibility color="#262262"/>
                     </IconButton>
                   </Box>
                 )}
           
                 {/* Educational File Box */}
                 {educationEntry.educational_file && (
                   <Box
                     display="flex"
                     alignItems="center"
                     justifyContent="space-between"
                     sx={{ padding: "1px 6px !important", cursor: 'pointer', border: "1.5px solid #262262", borderRadius: '5px' }}
                     marginY={1.5}
                     onClick={() => window.open(educationEntry.educational_file)}
                   >
                     <Typography variant="body1">
                    Educational File
                     </Typography>
                     <IconButton onClick={() => window.open(educationEntry.educational_file)}>
                      <MdVisibility color="#262262"/>
                     </IconButton>
                   </Box>
                 )}
               </React.Fragment>
             ))}
           </CustomAccordian>

               )
              }

            {experienceData && experienceData.length > 0 && (
              <CustomAccordian title="Proffesional Details">
          {experienceData.map((experienceEntry) => (
            <React.Fragment key={experienceEntry.id}>
              {/* Display Each File in Separate Box */}
              {experienceFilesMap[experienceEntry.id] && experienceFilesMap[experienceEntry.id].length > 0 && (
                experienceFilesMap[experienceEntry.id].map((file) => (
                  <Box
                    key={file.id}
                    display="flex !important"
                    alignItems="center !important"
                    justifyContent="space-between !important"
                    sx={{ padding: "1px 6px !important", marginY: 1.7,border: "1.5px solid #262262", borderRadius: '5px'  }} 
                    onClick={() => window.open(file.file)}
                  >
                    <Typography variant="body1" sx={{ flex: 1 }}>
                      {file.file_name}
                    </Typography>
                    <IconButton onClick={(e) => {
                      e.stopPropagation(); 
                      window.open(file.file);
                    }}>
                      <MdVisibility color="#262262"/>
                    </IconButton>
                  </Box>
                ))
              )}
            </React.Fragment>
          ))}
         </CustomAccordian>
          )
        }

{documentData.skills && documentData.skills.length > 0 && (
  <CustomAccordian title="Skills">
    {documentData.skills.map((skill) => (
      <Box key={skill.id}>
        {skill.file_uploaded ? (
          <Box
            display="flex !important"
            alignItems="center !important"
            justifyContent="space-between !important"
            sx={{ padding: "1px 6px !important", marginY: 1.7, border: "1.5px solid #262262", borderRadius: '5px' }}
            onClick={() => window.open(skill.file_uploaded, "_blank")}
          >
            <Typography variant="body1" sx={{ flex: 1 }}>
              {`${skill.skill}.pdf`}
            </Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                window.open(skill.file_uploaded, "_blank");
              }}
            >
              <MdVisibility color="#262262" />
            </IconButton>
          </Box>
        ) : (
          <Typography>No file found</Typography>
        )}
      </Box>
    ))}
  </CustomAccordian>
)}



{((documentData.diff_abled_file && documentData.diff_abled_file !== "https://iveri-docs.s3.amazonaws.com/False") ||
  (documentData.visa_file && documentData.visa_file !== "https://iveri-docs.s3.amazonaws.com/False")) && (
  <CustomAccordian title="Additional Data">
    {/* Differentially-abled File */}
    {documentData.diff_abled_file && documentData.diff_abled_file !== "https://iveri-docs.s3.amazonaws.com/False" ? (
      <Box>
        <Box
          display="flex !important"
          alignItems="center !important"
          justifyContent="space-between !important"
          sx={{ padding: "1px 6px !important", my: 1.7, border: "1.5px solid #262262", borderRadius: '5px' }}
          onClick={() => window.open(documentData.diff_abled_file, "_blank")}
        >
          <Typography variant="body1" sx={{ flex: 1 }}>
            Differentially-abled File
          </Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              window.open(documentData.diff_abled_file, "_blank");
            }}
          >
            <MdVisibility color="#262262" />
          </IconButton>
        </Box>
      </Box>
    ) : (
      <Typography>No file found</Typography>
    )}

    {/* Visa File */}
    {documentData.visa_file && documentData.visa_file !== "https://iveri-docs.s3.amazonaws.com/False" ? (
      <Box>
        <Box
          display="flex !important"
          alignItems="center !important"
          justifyContent="space-between !important"
          sx={{ padding: "1px 6px !important", my: 1.7, border: "1.5px solid #262262", borderRadius: '5px' }}
          onClick={() => window.open(documentData.visa_file, "_blank")}
        >
          <Typography variant="body1" sx={{ flex: 1 }}>
            Visa File
          </Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              window.open(documentData.visa_file, "_blank");
            }}
          >
            <MdVisibility color="#262262" />
          </IconButton>
        </Box>
      </Box>
    ) : (
      <Typography>No file found</Typography>
    )}
  </CustomAccordian>
)}


            </Grid>
        </Container>
        </>
        )
}

const FileViewer = ({ label, fileUrl }) => {
  return (
    <>
      {fileUrl && fileUrl !== 'null' ? (
        <Grid container my={2} display="flex" justifyContent="center">
          <Grid xs={8} sx={{ mb: 2, textAlign: 'center', mx: 'auto' }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography sx={{ textAlign: 'center' }} variant="h6">
                {label} File
              </Typography>
            </Box>
          </Grid>

          <Box my={2}>
            <iframe
              src={fileUrl}
              padding="15px"
              width="100%"
              height="250px"
              style={{ border: 'none', borderRadius: '10px' }}
            />
          </Box>

          <Box display="flex" mx="auto" justifyContent="center" mt={2}>
            <Button variant="outlined" color="primary" onClick={() => window.open(fileUrl)}>
              View
            </Button>
          </Box>
        </Grid>
      ) : (
        <Typography>No {label} file found</Typography>
      )}
    </>
  );
};
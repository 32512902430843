import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import OpenInNew from '@mui/icons-material/OpenInNew';
import instance from "../../globals/axios.js";
import DetailLabel from "../../components/Timeline/Timelinelabel.js"
import { MdVisibility } from 'react-icons/md';
import { IconButton } from "@mui/material";

import { styled } from '@mui/system';

const CustomCard = styled(Card)({
  boxShadow: 'none !important',
  padding: '10px !important',
  margin: '10px !important',
  backgroundColor: '#F8F9F9 !important',
  border: 'none !important', // Optional, in case there is a border
});


const EducationTimeline = () => {
  const [educationData, setEducationData] = useState([]);
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    instance.get('educational-details/')
      .then(response => {
        setEducationData(response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const handleExpandClick = (id) => {
    setExpanded(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: "0 !important",
          padding: "0 !important",
        },
        padding: "0 !important",
      }}
    >
      {educationData.map((detail, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot
              sx={{ backgroundColor: 'white', cursor: 'pointer', border: "2px black solid", padding: "0px !important" }} 
              onClick={() => handleExpandClick(detail.id)}  
            >
              {expanded[detail.id] ? (
                <RemoveIcon sx={{ color: 'black', fontSize: "15px !important" }} />  
              ) : (
                <AddIcon  sx={{ color: 'black', fontSize: "15px !important" }} />  
              )}
            </TimelineDot>
             <TimelineConnector sx={{backgroundColor: "black"}} />
          </TimelineSeparator>
          <TimelineContent 
          sx={{
              margin: "2 !important",
              padding: "2 !important",
            }}>
            <Paper
              sx={{
                p: "2 !important",
                backgroundColor: '#F8F9F9 !important',
                boxShadow: 'none !important',
                border: 'none !important',
              }}
            >
              <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {`${new Date(detail.start_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })} - ${new Date(detail.end_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}`}
              </Typography>

              <Typography  sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {detail.degree}
              </Typography>
              <Typography  sx={{ fontSize: "15px !important", fontWeight: "550 !important" }}>
                {detail.institute_name}
              </Typography>
              
              {/* Collapsible content for additional details */}
              <Collapse in={expanded[detail.id]} timeout="auto" unmountOnExit>
                <CardContent>
                  <DetailLabel label="College Name" value={detail.institute_name} />
                  <DetailLabel label="Specialization" value={detail.specialization} />
                  <DetailLabel label="Board" value={detail.board} />
                  <DetailLabel label="Score" value={detail.score} />
                  <DetailLabel label="Total Score" value={detail.total_score} />
                  <DetailLabel label="Score Unit" value={detail.score_unit} />
                  <DetailLabel label="Certificates" value={detail.certificate_name} />
                  
                  <Typography sx={{ fontSize: "20px", color: "#262262 !important", marginBottom: "3px" }}>
                    Projects:
                  </Typography>
                  {detail.projects && detail.projects.map(project => (
                    <Box key={project.id}>
                      <CustomCard sx={{ p: "10px !important", m: "10px !important", backgroundColor: "#F8F9F9 !important", boxShadow: "0px !important",}}>
                        <DetailLabel label="Project Title" value={project.project_title} />
                        <DetailLabel label="Project Description" value={project.project_desc} />
                        <DetailLabel label="Project Link" value={project.project_link} />
                      </CustomCard>
                    </Box>
                  ))}
                  
                  <Typography sx={{ fontSize: "20px", color: "#262262 !important", marginBottom: "3px" }}>
                    Internships:
                  </Typography>
                  {detail.internships && detail.internships.map(internship => (
                    <Box key={internship.id}>
                   <CustomCard sx={{ p: "10px !important", m: "10px !important", backgroundColor: "#F8F9F9 !important", boxShadow: "0px !important" }}>
                        <DetailLabel label="Internship Company" value={internship.internship_company} />
                        <DetailLabel label="Internship Role" value={internship.internship_role} />
                        <DetailLabel label="From" value={internship.internship_duration_from ? new Date(internship.internship_duration_from).toLocaleDateString() : "N.A."} />
                        <DetailLabel label="To" value={internship.internship_duration_to ? new Date(internship.internship_duration_to).toLocaleDateString() : "N.A."} />
                      </CustomCard>
                    </Box>
                  ))}
                  
                  {/* Display uploaded files */}
                  {detail.awards_file && detail.awards_file !== "https://iveri-docs.s3.amazonaws.com/null" && (
                      <Box 
                        display="flex !important" 
                        alignItems="center !important" 
                        justifyContent="space-between !important" 
                        border={1} 
                        borderRadius={2} 
                        sx={{ padding: "2px 6px !important", mt: "10px !important" }}
                        marginY={1.5} 
                        onClick={() => window.open(detail.awards_file, "_blank")}
                        style={{ cursor: 'pointer' }}
                      >
                        <Typography variant="body1">
                          Awards Document
                        </Typography>
                        <IconButton onClick={() => window.open(detail.awards_file, "_blank")}>
                          <MdVisibility />
                        </IconButton>
                      </Box>
                    )}
                 {detail.extra_curricular_file && detail.extra_curricular_file !== "https://iveri-docs.s3.amazonaws.com/null" && (
                   <Box 
                     display="flex !important" 
                     alignItems="center !important" 
                     justifyContent="space-between !important" 
                     border={1} 
                     borderRadius={2} 
                     sx={{ padding: "2px 6px !important", mt: 2, mb: 2 }}
                     style={{ cursor: 'pointer' }}
                     onClick={() => window.open(detail.extra_curricular_file, "_blank")}
                   >
                     <Typography variant="body1">
                       Extra Curricular Document
                     </Typography>
                     <IconButton onClick={() => window.open(detail.extra_curricular_file, "_blank")}>
                       <MdVisibility />
                     </IconButton>
                   </Box>
                 )}
                 
                 {detail.educational_file && detail.educational_file !== "https://iveri-docs.s3.amazonaws.com/null" && (
                   <Box 
                     display="flex !important" 
                     alignItems="center !important" 
                     justifyContent="space-between !important" 
                     border={1} 
                     borderRadius={2} 
                     sx={{ padding: "2px 6px !important", mt: 2, mb: 2 }}
                     style={{ cursor: 'pointer' }}
                     onClick={() => window.open(detail.educational_file, "_blank")}
                   >
                     <Typography variant="body1">
                       Educational Document
                     </Typography>
                     <IconButton onClick={() => window.open(detail.educational_file, "_blank")}>
                       <MdVisibility />
                     </IconButton>
                   </Box>
                 )}

                </CardContent>
              </Collapse>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default EducationTimeline;

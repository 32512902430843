import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import instance from "../../globals/axios.js";


const EducationTimlineResume = () => {
  const [educationData, setEducationData] = useState([]);

  useEffect(() => {
    instance.get('educational-details/')
      .then(response => {
        setEducationData(response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: "0 !important",
          padding: "0 !important",
        },
        padding: "0 !important",
      }}
    >
      {educationData.map((detail, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot
              sx={{ 
                backgroundColor: 'black', 
              }} 
            />
           <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ margin: "2 !important", padding: "2 !important" }}>
            <Paper
              sx={{
                p: "2 !important",
                backgroundColor: 'transparent',  
                boxShadow: 'none !important',
                border: 'none !important',
              }}
            >
             <Typography sx={{ color: "#414042 !important", fontSize: "16px", fontWeight: "600" }}>
                {`${new Date(detail.start_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })} - ${new Date(detail.end_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}`}
              </Typography>

              <Typography sx={{color: "#414042 !important", fontSize: "16px", fontWeight: "600"}}>
              {detail.specialization}
              </Typography>

              <Typography sx={{fontSize: "14px"}} color="#414042" >{`• Graduated from ${detail.board}`}</Typography>
              {detail.projects && detail.projects.map(project => (
                <Box key={project.id}>
                  <Typography sx={{fontSize: "14px"}} color="#414042" >{`• Member of this ${project.project_title}`}</Typography>
                </Box>
              ))}
              

              {detail.internships && detail.internships.map(internship => (
                <Box key={internship.id}>
                  <Typography sx={{fontSize: "14px"}} color="#414042" >{`• Done the ${internship.internship_company}`}</Typography>
                </Box>
              ))}
              
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default EducationTimlineResume;

import React from 'react';
import { Box, TextField, InputAdornment, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';

const CustomSelect = styled(Select)({
  color: 'grey',
  textTransform: 'none',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiSelect-icon': {
    display: 'none',
  },
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0', 
  },
});

const SearchFieldContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '50%',
  justifyContent: 'center',
});

const Divider = styled(Box)({
  width: '1px',
  backgroundColor: 'grey',
  height: '15px',
  margin: '0 10px',
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid rgba(197, 200, 204, 0.5)',
  borderRadius: '5px',
  padding: '5px',
  width: "80%",
});

const FormControlStyled = styled(FormControl)({
  width: '50%',
});

const MyButtonGroup = () => {
  const [jobTitle, setJobTitle] = React.useState('');
  const [location, setLocation] = React.useState('');

  const handleJobTitleChange = (event) => {
    setJobTitle(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  return (
    <ButtonContainer>
      <FormControlStyled variant="standard">
        <CustomSelect
          value={jobTitle}
          onChange={handleJobTitleChange}
          displayEmpty
          disableUnderline
          startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span style={{ color: 'rgba(197, 200, 204, 0.98)' }}>Job Title</span>;
            }
            return selected;
          }}
        >
          <MenuItem value="" disabled>
            Job Title
          </MenuItem>
          <MenuItem value="Software Engineer">Software Engineer</MenuItem>
          <MenuItem value="Product Manager">Product Manager</MenuItem>
          <MenuItem value="Designer">Designer</MenuItem>
        </CustomSelect>
      </FormControlStyled>
      <Divider />
      <FormControlStyled variant="standard">
        <CustomSelect
          value={location}
          onChange={handleLocationChange}
          displayEmpty
          disableUnderline
          startAdornment={<InputAdornment position="start"><LocationOnIcon /></InputAdornment>}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span style={{ color: 'rgba(197, 200, 204, 0.98)' }}>Location</span>;
            }
            return selected;
          }}
        >
          <MenuItem value="" disabled>
            Location
          </MenuItem>
          <MenuItem value="New York">New York</MenuItem>
          <MenuItem value="San Francisco">San Francisco</MenuItem>
          <MenuItem value="Los Angeles">Los Angeles</MenuItem>
        </CustomSelect>
      </FormControlStyled>
    </ButtonContainer>
  );
}

export default MyButtonGroup;

import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Radio,
  Container,
  TextField,
  FormControl,
} from "@mui/material";
import CustomButton from "../../../components/Button/CustomButtom";
import instance from "../../../globals/axios";
import ProfileHeader from "../../../components/ProfileHeader";
import BackButton from "../../../components/Button/BackButton";
import Sidebar from "../../../components/Sidebar";

const Lang = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [proficiency, setProficiency] = useState({
    read: "beginner",
    write: "beginner",
    speak: "beginner",
  });

  const proficiencyLevels = ["beginner", "intermediate", "expert"]; 

  const handleProficiencyChange = (skill, level) => {
    setProficiency((prev) => ({
      ...prev,
      [skill]: proficiencyLevels[level],
    }));
  };
  const handleFileUpload = (event) => {
    setUploadedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // First, submit the language data
      const languageData = new FormData();
      languageData.append("languageName", selectedLanguage);
      languageData.append("readingProficiency", proficiency.read);
      languageData.append("writingProficiency", proficiency.write);
      languageData.append("speakingProficiency", proficiency.speak);

      const languageResponse = await instance.post("language-data/", languageData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (languageResponse.status === 201) {
        // Extract the language ID from the response
        const languageId = languageResponse.data.id;

        // If a file is uploaded, submit it in a separate request
        if (uploadedFile) {
          const fileData = new FormData();
          fileData.append("file", uploadedFile);
          fileData.append("language_id", languageId);

          const fileResponse = await instance.post("language-file-upload/", fileData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (fileResponse.status === 200) {
            alert("Data and file uploaded successfully.");
          } else {
            console.error("Error uploading file");
          }
        } else {
          alert("Data Saved Successfully without file.");
        }
      } else {
        console.error("Error saving language data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Sidebar />
      <Container style={{ marginTop: "30px" }}>
        <ProfileHeader showUploadIcon={false} />
        <Box sx={{ padding: "16px", marginTop: "3rem" }}>
          <Box sx={{ marginBottom: "24px" }}>
            <Typography style={{ fontSize: "22px", fontWeight: 700 }}>
              Language Proficiency
            </Typography>
            <Typography style={{ fontSize: "18px", fontWeight: 500 }}>
              Add language
            </Typography>
          </Box>

          <FormControl fullWidth sx={{ marginBottom: "24px" }}>
            <TextField
              label="Enter Language"
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
            />
          </FormControl>

          <Box sx={{ marginBottom: "24px" }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <Typography style={{ fontSize: "11px", fontWeight: 600 }}>
                  Language Skill
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontSize: "11px", fontWeight: 600 }}>
                  Beginner
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontSize: "11px", fontWeight: 600 }}>
                  Intermediate
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontSize: "12px", fontWeight: 600 }}>
                  Expert
                </Typography>
              </Grid>

              {["read", "write", "speak"].map((skill) => (
                <React.Fragment key={skill}>
                  <Grid item xs={3}>
                    <Typography variant="body1" textTransform="capitalize">
                      {skill}
                    </Typography>
                  </Grid>
                  {[0, 1, 2].map((level) => (
                    <Grid item xs={3} key={level}>
                      <Radio
                        checked={proficiency[skill] === proficiencyLevels[level]}
                        onChange={() => handleProficiencyChange(skill, level)}
                        value={level}
                        name={`radio-button-${skill}`}
                        sx={{
                          color: "#262262",
                          "&.Mui-checked": {
                            color: "#262262",
                          },
                          padding: 0,
                        }}
                      />
                    </Grid>
                  ))}
                </React.Fragment>
              ))}
            </Grid>
          </Box>

          <Box sx={{ marginBottom: "24px", textAlign: "center" }}>
            <Typography variant="h6">{selectedLanguage}</Typography>
            <Box
              sx={{
                border: "1px dashed #ccc",
                padding: "16px",
                margin: "16px 0",
                textAlign: "center",
                borderRadius: "4px",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <input
                type="file"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  cursor: "pointer",
                }}
                onChange={handleFileUpload}
              />
              {uploadedFile ? uploadedFile.name : "Upload file"}
            </Box>
          </Box>

          <CustomButton onClick={handleSubmit}>{"Next"}</CustomButton>
          <BackButton />
        </Box>
      </Container>
    </>
  );
};

export default Lang;

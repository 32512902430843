import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { useState } from "react";
import { SERVER_BASE_ADDRESS } from "../globals/const";
import { InputLabel, Select, FormControl, MenuItem } from "@mui/material";
import instance from "../globals/axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useEffect } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import logo from "../../src/assets/iveri.png";
import CustomButton from "../components/Button/CustomButtom";

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    // margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "2rem",
  },
  submit: {
    margin: "20px",
  },
  heading: {
    fontSize: "34px !important",
    fontWeight: "600 !important",
  },
}));

export default function Register() {
  const classes = useStyles();
  const [creds, setCreds] = useState({});
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  function setCredField(key, value) {
    let _creds = creds;
    creds[key] = value;
    setCreds(_creds);
  }
  const [phoneNumber, setPhoneNumber] = useState("");
  const handlePhoneNumberChange = (formattedValue) => {
    setPhoneNumber(formattedValue);
    setShowOtpInput(true);
  };

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  function submitHandler(event) {
    event.preventDefault();

    setCredField("phone_number", phoneNumber);
    console.log(phoneNumber);
    if (phoneNumber.length !== 12) {
      alert("Not Valid Phone Number");
      return;
    }

    try {
      axios
        .post(SERVER_BASE_ADDRESS + "user/register/", creds)
        .then((res) => {
          localStorage.setItem("user_first_name", creds.first_name);
          localStorage.setItem("user_last_name", creds.last_name);
          localStorage.setItem("phone_number", creds.phone_number);
          localStorage.setItem("email", creds.email);
          localStorage.setItem("iveri_id", res.data.iveri_id);

          alert("Register Successful");
          setTimeout(() => {
            // window.location = "/verify";
            window.location = "/login";
          }, 200);
        })
        .catch((error) => {
          if (error.response.status === 400 && error.response.data.phone_number) {
            alert("Phone number already exists. Please use a different phone number.");
          } else if (error.response.status === 400 && error.response.data.email) {
            alert("Email already exists. Please use a different Email Id.");
          } else {
            alert("Error: " + error.message);
          }
        });
    } catch (error) {
      alert(error);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div style={{ marginTop: "20px" }}>
        <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
          <img mx="auto" alt="iVeri logo" src={logo} style={{ width: "150px", height: "80px" }} />
        </div>
        <Typography className={classes.heading}>Sign up</Typography>
        <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
          Enter the correct details to sign up your account!{" "}
        </Typography>
      </div>

      <Box mt="1rem" className={classes.paper}>
        <form className={classes.form} onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                onChange={(event) => setCredField("first_name", event.target.value)}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                onChange={(event) => setCredField("last_name", event.target.value)}
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <TextField
                variant="outlined"
                required
                fullWidth
                // id="email"
                label="Email Address"
                onChange={(event) => setCredField("email", event.target.value)}
                // name="email"
                // autoComplete="off"
              />
            </Grid>
            <Grid item fullWidth xs={12} style={{ marginTop: "10px" }}>
              <PhoneInput
                country={"in"}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                inputProps={{
                  required: true,
                }}
                inputStyle={{ width: "100%" }}
                containerStyle={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <InputLabel> Role</InputLabel>
              <FormControl variant="outlined" fullWidth>
                <Select onChange={(event) => setCredField("role", event.target.value)}>
                  <MenuItem value="1">Candidate</MenuItem>
                  <MenuItem value="2">BGV Agency Admin</MenuItem>
                  <MenuItem value="3">BGV Agency Verifier</MenuItem>
                  <MenuItem value="4">Employer</MenuItem>
                  <MenuItem value="8">Recruitment Agency</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <TextField
                variant="outlined"
                required
                fullWidth
                // name="password"
                label="Password"
                type="password"
                onChange={(event) => setCredField("password", event.target.value)}
                // id="password"
                // autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid container xs={9} mt={4} mx="auto" justifyContent="center">
            <CustomButton onClick={submitHandler}>{"Sign up"}</CustomButton>
            <Grid item>
              <Link to="/">Already have an account? Sign in</Link>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import NavBar from "../../../components/NavBar";
import Layout from "../../../components/Layout";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Backdrop from '@mui/material/Backdrop';
import ProfileHeader from "../../../components/ProfileHeader";

import {
  TextField,
  Button,
  Container,
  Typography,
  Card,
  Grid,
  IconButton,
  Badge,
  Box,
  List, ListItem, ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Autocomplete,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Close as CloseIcon } from "@mui/icons-material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import instance from "../../../globals/axios";
import { useDropzone } from 'react-dropzone';
import Sidebar from "../../../components/Sidebar";


export default function Skills() {
  const [inviteLink, setInviteLink] = useState("");


  const handleFileUpload = (event) => {
    const file = event.target.files[0];
  };
 
  const engineeringSkills = [
    'Algorithms',
  'Analytical Skills',
  'Big Data',
  'Calculating',
  'Compiling Statistics',
  'Data Analytics',
  'Data Mining',
  'Database Design',
  'Database Management',
  'Documentation',
  'Modeling',
  'Modification',
  'Needs Analysis',
  'Quantitative Research',
  'Quantitative Reports',
  'Statistical Analysis',
  'Applications',
  'Certifications',
  'Coding',
  'Computing',
  'Configuration',
  'Customer Support',
  'Debugging',
  'Design',
  'Development',
  'Hardware',
  'Implementation',
  'Information Technology',
  'Infrastructure',
  'Languages',
  'Maintenance',
  'Network Architecture',
  'Network Security',
  'Networking',
  'New Technologies',
  'Operating Systems',
  'Programming',
  'Restoration',
  'Security',
  'Servers',
  'Software',
  'Solution Delivery',
  'Storage',
  'Structures',
  'Systems Analysis',
  'Technical Support',
  'Technology',
  'Testing',
  'Tools',
  'Training',
  'Troubleshooting',
  'Usability',
  'Benchmarking',
  'Budget Planning',
  'Engineering',
  'Fabrication',
  'Following Specifications',
  'Operations',
  'Performance Review',
  'Project Planning',
  'Quality Assurance',
  'Quality Control',
  'Scheduling',
  'Task Delegation',
  'Task Management',
  'Blogging',
  'Digital Photography',
  'Digital Media',
  'Facebook',
  'Instagram',
  'Networking',
  'Pinterest',
  'SEO',
  'Social Media Platforms',
  'Twitter',
  'Web Analytics',
  'Client Relations',
  'Email',
  'Requirements Gathering',
  'Research',
  'Subject Matter Experts (SMEs)',
  'Technical Documentation',
    "Mechanical Engineering",
    "Electrical Engineering",
    "Civil Engineering",
    "Software Engineering",
    "Chemical Engineering",
    "Industrial Engineering",
    "Aerospace Engineering",
    "Biomedical Engineering",
    "Materials Engineering",
    "Environmental Engineering",
    "Structural Engineering",
    "Systems Engineering",
    "Robotics",
    "CAD/CAM Design",
    "Thermodynamics",
    "Fluid Mechanics",
    "Control Systems",
    "Machine Learning",
    "Data Analysis",
    "Project Management",
    "Technical Writing",
    "Problem Solving",
    "Mathematics",
    "Physics",
    "CAD Software (e.g., AutoCAD)",
    "Finite Element Analysis (FEA)",
    "Electronics",
    "Instrumentation",
    "Quality Assurance",
    "Teamwork",
    "Communication Skills",
    "Innovation",
    "Research and Development",
    "Programming (e.g., C++, Python)",
    "Digital Signal Processing",
    "Optimization",
    "CAD/CAM Software",
    "Manufacturing Processes",
    "Risk Assessment",
    "Safety Engineering",
    "Systems Integration",
    "Reliability Engineering",
    "Cost Analysis",
    "Energy Efficiency",
    "Ethical Decision-Making",
    "Leadership",
    "Time Management",
    "Critical Thinking",
    "Creativity",
    "Troubleshooting",
    "Continuous Learning",
    "Six Sigma",
    "Lean Manufacturing",
    "Failure Mode and Effects Analysis (FMEA)",
    "Root Cause Analysis",
    "Computer-Aided Engineering (CAE)",
    "Process Improvement",
    "Simulation and Modeling",
    "CAD Drafting",
    "Product Design",
    "Geotechnical Engineering",
    "Renewable Energy",
    "Hydrology",
    "3D Printing",
    "CAD/CAM Programming",
    "Statistical Analysis",
    "Supply Chain Management",
    "Technical Presentations",
    "Finite Element Analysis (FEA)",
    "Design for Manufacturing (DFM)",
    "Environmental Impact Assessment",
    "Occupational Health and Safety",
    "Patent Analysis",
    "Statistical Process Control (SPC)",
    "Geographic Information Systems (GIS)",
    "Circuit Design",
    "Embedded Systems",
    "Automation",
    "Machining",
    "Welding",
    "Chemistry",
    "Laboratory Techniques",
    "Computer Science",
    "Internet of Things (IoT)",
    "Agile Methodologies",
    "CAD/CAM Machining",
    "Instrument Calibration",
    "JavaScript",
    "Java",
    "C#",
    "Ruby",
    "PHP",
    "SQL",
    "HTML/CSS",
    "Python",
    "C++",
    "MATLAB",
    "R",
    "Version Control (e.g., Git)",
    "Web Development",
    "Database Design",
    "Algorithm Development",
    "Object-Oriented Programming (OOP)",
    "Software Testing",
  ];

  const [skills, setSkills] = useState([]);
  const [currentSkill, setCurrentSkill] = useState("");
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [invitedEmail, setInvitedEmail] = useState("");

  const handleSkillChange = (event) => {
    setCurrentSkill(event.target.value);
  };

  const handleAddSkill = () => {
    if (currentSkill.trim() !== "") {
      setSkills([...skills, currentSkill]);
      setCurrentSkill("");
    }
  };

  const handleRemoveSkill = (index) => {
    const updatedSkills = skills.filter((_, i) => i !== index);
    setSkills(updatedSkills);
  };

  const handleInviteDialogOpen = () => {
    setIsInviteDialogOpen(true);
  };

  const handleInviteDialogClose = () => {
    setIsInviteDialogOpen(false);
    setInvitedEmail(""); 
  };

  const handleInvite = () => {

    setInvitedEmail("");
    setIsInviteDialogOpen(false);
  };

  const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {

        const response = await instance.post("edit-skills/", skills);

            alert('Data saved successfully');
            setTimeout(() => {
              window.location = "/edit-document-locker";
            }, 200);
        
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const [data, setData] = useState([]);

    useEffect(() => {
      instance.get('edit-skills/') 
        .then(response => setData(response.data))
        .catch(error => console.error('Error fetching data:', error));
    }, []);

    const [openModal, setOpenModal] = useState(false);
    const [fileInput, setFileInput] = useState(null);
    const [selectedSkill, setSelectedSkill] = useState(null);

  
    const { getRootProps, getInputProps } = useDropzone({
      accept: 'image/*', // Adjust the accepted file types as needed
      onDrop: (acceptedFiles) => {
        const file = acceptedFiles[0];
        setFileInput(file);
      },
    });

    const handleUploadFile = () => {
      if (selectedSkill && fileInput) {
        const formData = new FormData();
        formData.append('skill_id', selectedSkill);
        formData.append('file', fileInput);
        
  
        instance.post('skills-file-upload/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            alert('File uploaded successfully')
            setOpenModal(false);
            window.location.reload();
          })
          .catch((error) => {
            console.error('Error uploading file:', error);
          });
      }
    };

    const [confirmationOpen, setConfirmationOpen] = React.useState(false);
    const [selectedSkillId, setSelectedSkillId] = React.useState(null);


    const handleDeleteConfirmation = (selectedSkillId) => {
      setConfirmationOpen(true);
      setSelectedSkillId(selectedSkillId);
    };
    
    const handleConfirmationYes = () => {
      instance.post('delete-entry/', {
        entry_type: 'skill',
        entry_id: selectedSkillId,
      })
        .then((response) => {
          console.log(response.data);
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setConfirmationOpen(false);
        });
    };

    return (
      <>
   <Sidebar />

        <Container style={{ marginTop: '20px' }}>
          <ProfileHeader showUploadIcon={false} />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            mt={2}
            mb={1}
          >
            <Grid item>
            <Typography variant="h6" sx={{ fontWeight: "600" }}>Add Skills</Typography>
            </Grid>
            
          </Grid>

          <Grid container alignItems="center" mt={3}>
          <Grid container spacing={2}>
  <Grid item xs={12} sm={4}>
    <Autocomplete
      id="add-skill-autocomplete"
      xs={6}
      options={engineeringSkills}
      value={currentSkill}
      onChange={(event, newValue) => {
        if (newValue !== null && newValue !== undefined) {
          setCurrentSkill(newValue);
        }
      }}
      inputValue={currentSkill}
      onInputChange={(event, newInputValue) => {
        if (newInputValue !== null && newInputValue !== undefined) {
          setCurrentSkill(newInputValue);
        } else {
          setCurrentSkill('');
        }
      }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Designer e.g"
          sx={{
            backgroundColor: "rgba(214,214,214, 0.3)",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
            height: "45px",
            borderRadius: "5px",
            padding: '0'
          }}
          fullWidth
        />
      )}
    />
  </Grid>
  <Grid item xs={12} sm={2}>
  <Button
    variant="contained"
    color="primary"
    onClick={handleAddSkill}
    fullWidth
    sx={{ 
      backgroundColor: "white", 
      minWidth: '30px', 
      minHeight: '35px', 
      color: "#666671",
      border: "1px solid #666671",
      textTransform: "none",
      boxShadow: "none",
      fontSize: "15px",
      padding: "10px 0px",
      '&:hover': {
        backgroundColor: "white",
        border: "1px solid #666671",
        boxShadow: "none",
      },
      '&:active': {
        backgroundColor: "white",
        border: "1px solid #666671",
        boxShadow: "none",
      }
    }}
  >
    +Add
  </Button>
</Grid>

</Grid>



            <Grid mt={2} item xs={12}>
              {
skills.map((skill, index) => (
  <React.Fragment key={index}>
    <Card
      fullwidth
      sx={{
        my: 2,
        mx: 2,
        p: 1,
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: "rgba(214,214,214, 0.3)",
        borderRadius: "5px",
        height: "45px", // Set the height
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none',
          },
          '&:hover fieldset': {
            border: 'none',
          },
          '&.Mui-focused fieldset': {
            border: 'none',
          },
          '& input': {
            padding: '10px 14px', // Adjust padding to fit height
          },
        },
      }}
    >
      <Grid container>
        <Grid item xs={12} fullWidth>
          <Typography sx={{fontSize: "15px"}}>
            <IconButton color="error" sx={{ alignItems: "end" }} onClick={() => handleRemoveSkill(index)}>
              <HighlightOffIcon />
            </IconButton>
            {skill}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  </React.Fragment>
))
}

              { skills.length>0 && (
                       <Grid
                       container
                       justifyContent="center"
                       my={3}
                     >
                         <Grid xs={12} sm={6}>
                           <Button
                               variant="contained"
                               onClick={handleSubmit}
                               fullWidth
                               sx={{backgroundColor: "#272260"}}
                           >
                               Save
                           </Button>
                 </Grid>
                         </Grid>
              )}
            </Grid>
          </Grid>

          <List p={3}>
          <Grid container spacing={2} mt={3}>
            {data.map((skill, index) => (
              <Grid key={index} item xs={12} sm={6} md={6} lg={6}>
                <Box key={index} my={2} p={1}>
                  <Card fullWidth elevation={4} sx={{ borderRadius: '10px' }}>
                    <ListItem>
                      <Grid container sx={{ textAlign: "center" }}>
                        <Grid xs={12} item my={1} sx={{ textAlign: "center" }}>
                          <ListItemText
                            fullWidth
                            sx={{ textAlign: "center" }}
                            primary={skill.skill}
                            secondary={`${!skill.file_uploaded ? 'No File Found' : ''}`}
                          />
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={2} sx={{ textAlign: "center", my: 1 }}>
                         
                              <IconButton onClick={() => handleDeleteConfirmation(skill.id)}>
                                <DeleteForeverIcon color="error" />
                              </IconButton>
                          </Grid>
                          <Grid item xs={9} sx={{ textAlign: "center", my: 1 }}>
                            {skill.file_uploaded ? (
                              <>
                                <a href={skill.file_uploaded} target="_blank" rel="noopener noreferrer">
                                  <Button fullWidth variant="outlined">
                                    View File
                                  </Button>
                                </a>
                              </>
                            ) : (
                              <>
                                <Button fullWidth variant="outlined" sx={{border: "1px solid #272260", color: "#272260"}} onClick={() => { setOpenModal(true); setSelectedSkill(skill.id); }}>
                                  Upload File
                                </Button>
                              </>
                            )}
                          </Grid>
                          
                        </Grid>
                      </Grid>

                      <Dialog
                          open={confirmationOpen}
                          onClose={() => setConfirmationOpen(false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          // BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
                          sx={{ backgroundColor: '#fff' }} // Adjust the alpha value for transparency
                        >
                          <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Are you sure you want to delete this skill?
                              
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => setConfirmationOpen(false)} color="primary">
                              No
                            </Button>
                            <Button onClick={handleConfirmationYes} color="primary">
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                    </ListItem>
                  </Card>
                </Box>
                </Grid>
              ))}
</Grid>
            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
              <DialogTitle>Upload File</DialogTitle>
              <DialogContent>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop an image file here, or click to select one</p>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModal(false)}>Cancel</Button>
                <Button onClick={handleUploadFile}>Upload</Button>
              </DialogActions>
            </Dialog>
          </List>
         

          
          <Grid container spacing={2} mt={3}>   
            <Grid item xs={12}>
            {/* 
            commented endorsements for now

            <Button variant="contained" color="primary" onClick={handleInviteDialogOpen}>
              Invite People
            </Button>

            */}

            <Dialog
              open={isInviteDialogOpen}
              onClose={handleInviteDialogClose}
              fullWidth
              maxWidth="xs"
            >
              <DialogTitle>Invite People</DialogTitle>
              <DialogContent>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={invitedEmail}
                  onChange={(event) => setInvitedEmail(event.target.value)}
                  sx={{ marginTop: 2 }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleInviteDialogClose}>Cancel</Button>
                <Button color="primary" onClick={handleInvite}>
                  Invite
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          
          </Grid>

         

      </Container>
      </>
    );
  }

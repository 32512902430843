import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { MdVisibility } from 'react-icons/md';
import instance from "../../globals/axios.js";
import DetailLabel from "../../components/Timeline/Timelinelabel.js";

const ExperienceTimeline = () => {
  const [experiences, setExperiences] = useState([]);
  const [experiencesFile, setExperiencesFile] = useState([]);
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    instance
      .get("experience-details/")
      .then((response) => {
        setExperiences(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    instance
      .get("upload-experience-details/")
      .then((response) => {
        setExperiencesFile(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleExpandClick = (id) => {
    setExpanded(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: "0 !important",
          padding: "0 !important",
        },
        padding: "0 !important",
      }}
    >
      {experiences.map((experience, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot
              sx={{ backgroundColor: 'white', cursor: 'pointer', border: "2px black solid", padding: "0px !important" }} 
              onClick={() => handleExpandClick(experience.id)}  
            >
              {expanded[experience.id] ? (
                <RemoveIcon sx={{ color: 'black', fontSize: "15px !important" }}  />  
              ) : (
                <AddIcon sx={{ color: 'black', fontSize: "15px !important" }} />  
              )}
            </TimelineDot>
            <TimelineConnector sx={{backgroundColor: "black"}}/>
          </TimelineSeparator>
          <TimelineContent
            sx={{
              margin: "2 !important",
              padding: "2 !important", 
            }}
          >
            <Paper
              sx={{
                p: "2 !important",
                backgroundColor: '#F8F9F9 !important',
                boxShadow: 'none !important',
                border: 'none !important',
              }}
            >
              <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {`${experience.start_date ? new Date(experience.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' }) : "N.A."}`}
                -
                {` ${experience.end_date ? new Date(experience.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' }) : "Till Now"}`}
              </Typography>

              {/* Company name and designation only visible when not expanded */}
              {!expanded[experience.id] && (
                <>
                  <Typography sx={{ fontSize: "18px !important", fontWeight: "600!important" }}>
                    {experience.company || "N.A."}
                  </Typography>
                  <Typography sx={{ fontSize: "18px !important", fontWeight: "600!important" }}>
                    {experience.designation || "N.A."}
                  </Typography>
                </>
              )}

              {/* Collapsible content for additional details */}
              <Collapse in={expanded[experience.id]} timeout="auto" unmountOnExit>
                <CardContent>
                  <DetailLabel label="Company Name" value={`${experience.company || "N.A."}`} />
                  <DetailLabel label="Company Description" value={`${experience.description || "N.A."}`} />
                  <DetailLabel label="Website" value={`${experience.website || "N.A."}`} />
                  <DetailLabel label="Fixed Salary" value={` ${Number(experience.fixed_salary_amount || "N.A.").toLocaleString()} ${experience.fixed_salary_currency || "N.A."}`} />
                  <DetailLabel label="Variable Salary" value={`${Number(experience.variable_salary_amount || "N.A").toLocaleString()} ${experience.variable_salary_currency || "N.A."}`} />
                  <DetailLabel label="Total Salary"value={`${(Number(experience.fixed_salary_amount || 0) + Number(experience.variable_salary_amount || 0)).toLocaleString()} ${experience.fixed_salary_currency || "N.A."} `}/>
                  <DetailLabel label="Other Benefits" value={experience.perks || "N.A."} />
                  <DetailLabel label="Accommodation" value={`${experience.accommodation_furniture || "N.A."} , ${experience.accommodation_family || "N.A."}`} />
                  <DetailLabel label="Expected Salary" value={` ${Number(experience.expected_salary|| "N.A.").toLocaleString()} ${experience.currency || "N.A."}`} />
                  <DetailLabel label="Notice Period" value={`${experience.notice_period || "N.A."}`} />
                  <DetailLabel label="Notice Period negotiable days " value={`${experience.days_negotiable || "N.A."} days`} />
                  <DetailLabel label="Reporting To" value={experience.reporting_to || "N.A."} />
                  <DetailLabel label="Reportee" value={experience.reportee || "N.A."} />
                  <DetailLabel label="Role" value={experience.role || "N.A."} />
                  <DetailLabel label="Brief Role" value={experience.brief_role || "N.A."} />
                  <DetailLabel label="Work Location" value={experience.work_location || "N.A."} />
                  <DetailLabel label="Office Type" value={experience.office_type || "N.A."} />
                  
                  {experience.kra && experience.kra.length > 0 && (
                   <Box sx={{ mt: 2 }}>
                     {experience.kra.map((kraItem, kraIndex) => (
                      <Box key={kraIndex} sx={{ mb: 1 }}>
                      <Typography sx={{ fontWeight: 'bold !important', color: "#262262" }}>
                        KRA: 
                        <Typography component="span" sx={{ fontWeight: 'bold !important', color: "#000000" }}>
                          {` - ${kraItem.kra}`}
                        </Typography>
                      </Typography>
                      <Typography sx={{ fontWeight: 'bold !important', color: "#262262" }}>
                        Achievement:
                        <Typography component="span" sx={{ fontWeight: 'bold !important', color: "#000000" }}>
                          {` - ${kraItem.achievement}`}
                        </Typography>
                      </Typography>
                    </Box>
                     ))}
                   </Box>
                 )}

                 {/* Display files associated with this experience */}
                 {experiencesFile.filter(file => file.experience_details === experience.id).map((file, index) => (
                    <Box 
                      key={index}
                      display="flex !important" 
                      alignItems="center !important" 
                      justifyContent="space-between !important" 
                      border={1} 
                      borderRadius={2} 
                      sx={{ padding: "2px 6px !important", mt: 2, mb: 2 }}
                      style={{ cursor: 'pointer' }}
                      onClick={() => window.open(file.file, "_blank")}
                    >
                      <Typography variant="body1">
                        {file.file_name}
                      </Typography>
                      <IconButton onClick={(e) => {
                          e.stopPropagation(); 
                          window.open(file.file, "_blank");
                        }}>
                        <MdVisibility />
                      </IconButton>
                    </Box>
                  ))}
                </CardContent>
              </Collapse>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default ExperienceTimeline;

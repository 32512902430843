import React, { useState } from "react";
import { degreeOptions } from "./data";
import { styled } from "@mui/material/styles";

import {
  Button,
  Container,
  Typography,
  TextField,
  Select,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Grid,
  Box,
  FormControl,
} from "@mui/material";
import instance from "../../../globals/axios.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDropzone } from "react-dropzone";
import ProfileHeader from "../../../components/ProfileHeader.js";
import BackButton from "../../../components/Button/BackButton.js";
import CustomButton from "../../../components/Button/CustomButtom.js";
import Sidebar from "../../../components/Sidebar.js";

const DropzoneContainer = styled("div")({
  border: "2px dashed #ccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginBottom: "20px",
});

const EducationDetail = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [educationType, setEducationType] = useState("");
  const [degree, setDegree] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [instituteName, setInstituteName] = useState("");
  const [passingYear, setPassingYear] = useState("");
  const [board, setBoard] = useState("");
  const [score, setScore] = useState("");
  const [totalScore, setTotalScore] = useState("");
  const [awards, setAwards] = useState("");
  const [extraCurricular, setExtraCurricular] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const [certificateName, setCertificateName] = useState("");
  const [scoreUnit, setScoreUnit] = useState("");
  const [awardsFile, setAwardsFile] = useState(null);
  const [extraCurricularFile, setExtraCurricularFile] = useState(null);
  const [educationFile, setEducationFile] = useState(null);
  const [waitingForResult, setWaitingForResult] = useState(false);

  const [loading, setLoading] = useState(false);

  const onDropExtraCurricular = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setExtraCurricularFile(acceptedFiles[0]);
    }
  };

  const onDropEducation = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setEducationFile(acceptedFiles[0]);
    }
  };

  const onDropAwards = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setAwardsFile(acceptedFiles[0]);
    }
  };

  const { getRootProps: getAwardsRootProps, getInputProps: getAwardsInputProps } = useDropzone({
    accept: ".pdf, .jpeg, .jpg, .png",
    onDrop: onDropAwards,
  });

  // For Extra Curricular
  const { getRootProps: getExtraCurricularRootProps, getInputProps: getExtraCurricularInputProps } =
    useDropzone({
      accept: ".pdf",
      onDrop: onDropExtraCurricular,
    });

  // For Education
  const { getRootProps: getEducationRootProps, getInputProps: getEducationInputProps } =
    useDropzone({
      accept: ".pdf",
      onDrop: onDropEducation,
    });

  const [internshipFormData, setInternshipFormData] = useState([
    {
      internship_company: "",
      internship_role: "",
      internship_duration_from: null,
      internship_duration_to: null,
    },
  ]);

  const [projectFormData, setProjectFormData] = useState([
    {
      project_title: "",
      project_desc: "",
      project_link: "",
    },
  ]);

  const handleInternChange = (event, index) => {
    const { name, value } = event.target;
    const updatedInternshipFormData = [...internshipFormData];
    updatedInternshipFormData[index][name] = value;
    setInternshipFormData(updatedInternshipFormData);
  };

  const handleDateChange = (date, name, index) => {
    const updatedInternshipFormData = [...internshipFormData];
    updatedInternshipFormData[index][name] = date;
    setInternshipFormData(updatedInternshipFormData);
  };

  const handleAddBoxIntern = () => {
    setInternshipFormData([
      ...internshipFormData,
      {
        internship_company: "",
        internship_role: "",
        internship_duration_from: null,
        internship_duration_to: null,
      },
    ]);
  };

  const handleRemoveBoxIntern = (index) => {
    const updatedInternshipFormData = [...internshipFormData];
    updatedInternshipFormData.splice(index, 1);
    setInternshipFormData(updatedInternshipFormData);
  };

  const handleProjectChange = (event, index) => {
    const { name, value } = event.target;
    const updatedProjectFormData = [...projectFormData];
    updatedProjectFormData[index][name] = value;
    setProjectFormData(updatedProjectFormData);
  };

  const handleAddBoxProject = () => {
    setProjectFormData([
      ...projectFormData,
      {
        project_title: "",
        project_desc: "",
        project_link: "",
      },
    ]);
  };

  const handleRemoveBoxProject = (index) => {
    const updatedProjectFormData = [...projectFormData];
    updatedProjectFormData.splice(index, 1);
    setProjectFormData(updatedProjectFormData);
  };

  const handleSaveClick = (event) => {
    event.preventDefault();

    const sendData = new FormData();
    sendData.append("type", educationType);
    sendData.append("degree", degree);
    sendData.append("specialization", specialization);
    sendData.append("institute_name", instituteName);
    if (startDate !== null && endDate !== null) {
      if (endDate > startDate) {
        sendData.append("start_date", startDate.toISOString());
        sendData.append("end_date", endDate.toISOString());
      } else {
        alert("End Date must be later than Start Date.");
        return;
      }
    } else {
      alert("Please select Valid Course Duration.");
      return;
    }

    sendData.append("passing_year", passingYear);
    sendData.append("board", board);
    sendData.append("score", score);
    sendData.append("total_score", totalScore);
    sendData.append("awards", awards);
    sendData.append("extra_curricular", extraCurricular);
    sendData.append("video_file", videoFile);
    sendData.append("certificate_name", certificateName);
    sendData.append("score_unit", scoreUnit);
    sendData.append("result_waiting", waitingForResult);

    const formattedInternshipData = internshipFormData.map((item) => {
      return {
        internship_company: item.internship_company,
        internship_role: item.internship_role,
        internship_duration_from: item.internship_duration_from,
        internship_duration_to: item.internship_duration_to,
      };
    });

    // Convert projectFormData to a format that can be sent to the server
    const formattedProjectData = projectFormData.map((item) => {
      return {
        project_title: item.project_title,
        project_desc: item.project_desc,
        project_link: item.project_link,
      };
    });

    sendData.append("internship_data", JSON.stringify(formattedInternshipData));
    sendData.append("project_data", JSON.stringify(formattedProjectData));

    instance
      .post("educational-details/", sendData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // Extract the educational_details_id from the response
        setLoading(true);
        const educational_details_id = res.data.data.educational_details_id;
        console.log(res.data);
        const formData = new FormData();

        formData.append("educational_details_id", educational_details_id);
        formData.append("awards_file", awardsFile);
        formData.append("educational_file", educationFile);
        formData.append("extra_curricular_file", extraCurricularFile);

        // Second API call to upload files
        instance
          .post("create-educational-files/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            setLoading(false);
            alert("Data saved successfully!");
            // setTimeout(() => {
            //   window.location = "/edit-experience-details";
            // }, 200);
          })
          .catch((error) => {
            setLoading(false);
            alert("Error uploading files. Please try again.");
          });
      })
      .catch((error) => {
        setLoading(false);
        alert("Error creating/retrieving EducationalDetails entry. Please try again.");
      });
  };

  return (
    <>
      <Sidebar />
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        <ProfileHeader showUploadIcon={false} />
        <Grid item xs={12} my={1}>
          <Typography
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "5px",
            }}>
            Educational Details
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} container fullWidth>
            {/* Type */}
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1">Education Type</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  id="type-dropdown"
                  value={educationType}
                  name="type_dropdown"
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    height: "45px",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  label="Education Type"
                  variant="outlined"
                  onChange={(e) => setEducationType(e.target.value)}>
                  <MenuItem value="School">School</MenuItem>
                  <MenuItem value="Diploma">Diploma</MenuItem>
                  <MenuItem value="Graduation">Graduation</MenuItem>
                  <MenuItem value="Post-Graduation">Post-Graduation</MenuItem>
                  <MenuItem value="Doctorate">Doctorate (PhD)</MenuItem>
                  <MenuItem value="Professional Certification">Professional Certification</MenuItem>
                  <MenuItem value="Voluntary Certification">Voluntary Certification</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* Degree */}
          {educationType !== "Professional Certification" &&
            educationType !== "Voluntary Certification" && (
              <Grid item xs={12} container fullWidth>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">Degree</Typography>
                </Grid>
                <Grid my={2} item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      labelId="degree-dropdown-label"
                      id="degree-dropdown"
                      value={degree}
                      name="degree_dropdown"
                      label="Degree"
                      sx={{
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        height: "45px",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      onChange={(e) => setDegree(e.target.value)}
                      disabled={!educationType}>
                      {degreeOptions[educationType] &&
                        degreeOptions[educationType].map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}

          {/* Specialisation */}
          {educationType !== "Professional Certification" &&
            educationType !== "Voluntary Certification" && (
              <Grid item xs={12} container fullWidth>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">Specialisation</Typography>
                </Grid>
                <Grid my={2} item xs={12} md={6}>
                  <TextField
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      height: "45px",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    placeholder="Specialisation"
                    value={specialization}
                    name="specialization_dropdown"
                    onChange={(e) => setSpecialization(e.target.value)}
                  />
                </Grid>
              </Grid>
            )}

          {/* Certificate Name */}
          {(educationType === "Professional Certification" ||
            educationType === "Voluntary Certification") && (
            <Grid item xs={12} container fullWidth>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Certificate Name</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    height: "45px",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  value={certificateName}
                  onChange={(e) => setCertificateName(e.target.value)}
                />
              </Grid>
            </Grid>
          )}

          {/* Passing Year */}

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Course Duration</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      width: "100%",
                      height: "45px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    placeholder="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      width: "100%",
                      height: "45px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={waitingForResult}
                  onChange={(e) => setWaitingForResult(e.target.checked)}
                />
              }
              label="Waiting for Result"
            />
          </Grid>

          {/*

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Passing Year"
              value={passingYear}
              name="passing_year"
              onChange={(e) => setPassingYear(e.target.value)}
            />
          </Grid>

          */}

          {/* Board */}
          {(educationType === "School" || educationType === "Diploma") && (
            <React.Fragment>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Board</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    labelId="board-dropdown-label"
                    id="board-dropdown"
                    value={board}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      height: "45px",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    name="board_dropdown"
                    onChange={(e) => setBoard(e.target.value)}>
                    <MenuItem value="ICSE">ICSE</MenuItem>
                    <MenuItem value="CBSE">CBSE</MenuItem>
                    <MenuItem value="IGCSE">IGCSE</MenuItem>
                    <MenuItem value="IB">IB</MenuItem>
                    <MenuItem value="State Board">State Board</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </React.Fragment>
          )}

          {(educationType === "Graduation" ||
            educationType === "Post-Graduation" ||
            educationType === "Doctorate") && (
            <React.Fragment>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">University Name</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    height: "45px",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                  placeholder="University Name"
                  value={board}
                  name="board"
                  onChange={(e) => setBoard(e.target.value)}
                />
              </Grid>
            </React.Fragment>
          )}

          {/* Institute Name */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Institute Name</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                height: "45px",
                borderRadius: "5px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              placeholder="Institute Name"
              value={instituteName}
              name="institute_name"
              onChange={(e) => setInstituteName(e.target.value)}
            />
          </Grid>

          {/* Score */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Score</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                height: "45px",
                borderRadius: "5px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              placeholder="Score"
              value={score}
              name="score"
              onChange={(e) => setScore(e.target.value)}
            />
          </Grid>

          {/* Total Score */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Total Score</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Total Score"
              value={totalScore}
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                height: "45px",
                borderRadius: "5px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              name="total_score"
              onChange={(e) => setTotalScore(e.target.value)}
            />
          </Grid>

          {/* Score Unit */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Score Unit</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <Select
                fullWidth
                labelId="score-unit-dropdown-label"
                id="score-unit-dropdown"
                value={scoreUnit}
                name="score_unit_dropdown"
                placeholder="Grading"
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  height: "45px",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                onChange={(e) => setScoreUnit(e.target.value)}>
                <MenuItem value="percentage">Percentage</MenuItem>
                <MenuItem value="CGPA">CGPA</MenuItem>
                <MenuItem value="Marks">Marks</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid my={2} px={2} container spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid spacing={2} container style={{ display: "flex", flexDirection: "column" }}>
                {projectFormData.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Typography sx={{ my: 1 }} variant="subtitle1">
                      Project Title
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{ my: 1 }}
                      variant="outlined"
                      placeholder="Project Title"
                      name="project_title"
                      sx={{
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        height: "45px",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      value={item.project_title}
                      onChange={(event) => handleProjectChange(event, index)}
                    />
                    <Typography sx={{ my: 1 }} variant="subtitle1">
                      Project Description
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{ my: 1 }}
                      variant="outlined"
                      placeholder="Project Description"
                      name="project_desc"
                      sx={{
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        height: "45px",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      value={item.project_desc}
                      onChange={(event) => handleProjectChange(event, index)}
                    />
                    <Typography sx={{ my: 1 }} variant="subtitle1">
                      Project Link
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{ my: 1 }}
                      variant="outlined"
                      placeholder="Project Link"
                      name="project_link"
                      sx={{
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        height: "45px",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      value={item.project_link}
                      onChange={(event) => handleProjectChange(event, index)}
                    />
                    {index > 0 && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleRemoveBoxProject(index)}>
                        - Remove Project
                      </Button>
                    )}
                  </Grid>
                ))}
                {/* <Grid flexDirection="row-reverse" item xs={12}>
                  <Button variant="outlined" color="primary" onClick={handleAddBoxProject}>
                    + Add Internship
                  </Button>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid spacing={2} container>
                {internshipFormData.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Typography sx={{ my: 1 }} variant="subtitle1">
                      Internship Company
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{ my: 1 }}
                      variant="outlined"
                      placeholder="Internship Company"
                      value={item.internship_company}
                      name="internship_company"
                      sx={{
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        height: "45px",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      onChange={(event) => handleInternChange(event, index)}
                    />
                    <Typography sx={{ my: 1 }} variant="subtitle1">
                      Internship Role
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{ my: 1 }}
                      variant="outlined"
                      placeholder="Internship Role"
                      value={item.internship_role}
                      name="internship_role"
                      sx={{
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        height: "45px",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      onChange={(event) => handleInternChange(event, index)}
                    />
                    <Typography sx={{ my: 1.5 }} variant="subtitle1">
                      Duration
                    </Typography>
                    <Grid fullWidth container>
                      <Grid item xs={12} md={6} pr={1} pt={1}>
                        <LocalizationProvider sx={{ my: 1 }} dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="From"
                            sx={{
                              backgroundColor: "rgba(214,214,214, 0.3)",
                              borderRadius: "5px",
                              width: "100%",
                              height: "45px",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "none",
                                },
                                "&:hover fieldset": {
                                  border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "none",
                                },
                                "& input": {
                                  padding: "10px 14px",
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={6} pt={1}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="To"
                            sx={{
                              backgroundColor: "rgba(214,214,214, 0.3)",
                              borderRadius: "5px",
                              width: "100%",
                              height: "45px",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "none",
                                },
                                "&:hover fieldset": {
                                  border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "none",
                                },
                                "& input": {
                                  padding: "10px 14px",
                                },
                              },
                            }}
                            value={item.internship_duration_to}
                            onChange={(date) =>
                              handleDateChange(date, "internship_duration_to", index)
                            }
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    {index > 0 && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleRemoveBoxIntern(index)}>
                        - Remove Internship
                      </Button>
                    )}
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button variant="outlined" color="primary" onClick={handleAddBoxIntern}>
                    + Add Internship
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} mt={2}>
              <Grid item>
                <Typography variant="subtitle1">Awards</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Awards"
                  multiline
                  rows={3}
                  value={awards}
                  name="awards"
                  onChange={(e) => setAwards(e.target.value)}
                />
              </Grid>
            </Grid> 
            <Grid item xs={12} md={6} mt={2}>
              <Grid item>
                <Typography variant="subtitle1">Extra Curricular</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Extra Curricular"
                  multiline
                  rows={3}
                  value={extraCurricular}
                  name="extra_curricular"
                  onChange={(e) => setExtraCurricular(e.target.value)}
                />
              </Grid> 
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid p={4}>
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", fontAlign: "center" }}
                  gutterBottom>
                  Upload Awards File
                </Typography>
                <DropzoneContainer {...getAwardsRootProps()}>
                  <input {...getAwardsInputProps()} />
                  <Typography variant="body1">
                    Drag and drop a PDF file here, or click to select one.
                  </Typography>
                </DropzoneContainer>
                {awardsFile && (
                  <Typography variant="body1">Awards File: {awardsFile.name}</Typography>
                )}
              </Grid>
            </Grid> 
             <Grid item xs={12} md={4}>
              <Grid p={4}>
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", fontAlign: "center" }}
                  gutterBottom>
                  Upload Education File
                </Typography>
                <DropzoneContainer {...getEducationRootProps()}>
                  <input {...getEducationInputProps()} />
                  <Typography variant="body1">
                    Drag and drop a PDF file here, or click to select one.
                  </Typography>
                </DropzoneContainer>
                {educationFile && (
                  <Typography variant="body1">Education File: {educationFile.name}</Typography>
                )}
              </Grid>
            </Grid> 
             <Grid item xs={12} md={4}>
              <Grid p={4}>
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", fontAlign: "center" }}
                  gutterBottom>
                  Upload Extra Curricular File
                </Typography>
                <DropzoneContainer {...getExtraCurricularRootProps()}>
                  <input {...getExtraCurricularInputProps()} />
                  <Typography variant="body1">
                    Drag and drop a PDF file here, or click to select one.
                  </Typography>
                </DropzoneContainer>
                {extraCurricularFile && (
                  <Typography variant="body1">
                    Extra Curricular File: {extraCurricularFile.name}
                  </Typography>
                )}
              </Grid>
            </Grid> 
          </Grid>
        </Grid>

        <Box mx="auto">
          {/* <BackButton /> */}
          <CustomButton onClick={handleSaveClick}>{"Next"}</CustomButton>
          <BackButton />
        </Box>
      </Container>
    </>
  );
};

export default EducationDetail;

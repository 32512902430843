import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VerifiedIcon from "@mui/icons-material/Verified";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import {
  Container,
  Card,
  CardContent,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Chip,
  TableRow,
  Paper,
  Grid,
  Box,
  FormControl,
  TextField,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select, 
  MenuItem,
  Checkbox,
} from "@mui/material";
import { MdVisibility } from 'react-icons/md';
import api from "../../globals/axios.js";
import { useHistory } from "react-router-dom";
import { SERVER_BASE_ADDRESS } from "../../globals/const.js";
import instance from "../../globals/axios.js";
import { Check, Clear, OpenInNew } from "@mui/icons-material";
import {
  LocationOn,
  MonetizationOn,
  Event,
  Description,
  AccountCircle,
  Business,
  AccountBalance,
  Person,
  EventNote,
} from "@mui/icons-material";
import {
  FaLinkedin,
  FaBehance,
  FaGithub,
  FaFacebook,
  FaUser,
  FaCheck,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import DocumentLocker from "./EditPages/DocumentLocker.js";
import NavBar from "../../components/NavBar.js";
import ProfileHeader from "../../components/ProfileHeader.js";
import Sidebar from "../../components/Sidebar.js";
import DoneIcon from '@mui/icons-material/Done';
import { styled } from '@mui/material/styles';
import AdditionalInfo from "./EditPages/AdditionalInfo.js";
import EducationTimeline from "../../components/Timeline/EducationTimeline.js";
import ExperienceTimeline from "../../components/Timeline/ExperienceTimeline.js";
import CustomTypography from "../../components/Textfield/CustomTypography.js";

const handlePageSelect = (event) => {
  const selectedPage = event.target.value;

  if (selectedPage) {
    window.location.href = selectedPage; 
  }
};

export default function ViewProfile() {
  const [personalDetails, setPersonalDetails] = useState({});
  const [mediaLinks, setMediaLinks] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [skills, setSkills] = useState([]);
  const [langData, setLangData] = useState(null);
  const [iveriId, setIveriId] = useState(localStorage.getItem("iveri_id"));
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem("phone_number"));
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [gender, setGender] = useState(localStorage.getItem("gender"));
  const [usePermanentAddress, setUsePermanentAddress] = useState(true);
  const [selectedOption, setSelectedOption] = useState('profile');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const history = useHistory();

  function convert_python_case(str) {
    return str
      .replace("_", " ")
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  }

  useEffect(() => {
    instance
      .get(SERVER_BASE_ADDRESS + "view-profile/")
      .then((res) => res.data)
      .then((data) => {
        setPersonalDetails(data.personal_details);
        setLangData(data.personal_details.language_data);
        let str = data.personal_details.hobbies;
        let validJsonStr = str.replace(/'/g, '"');
        let myArray = JSON.parse(validJsonStr);
        setHobbies(myArray);
        setMediaLinks(data.links);
      });
  }, []);

  const handleEditBtn = () => {
    history.push("/edit-profile-menu");
  };
  const handleBasicInfoBtn = () => {
    history.push("/edit-basic-info");
  };
  const handlePersonalInfoBtn = () => {
    history.push("/personal-details-info");
  };
  
  const handleSkillBtn = () => {
    history.push("/edit-skills");
  }

  const handleAddtionalBtn = () => {
    history.push("/edit-additional-info");
  };
  
  const handleAddressbtn = () => {
    history.push("/edit-address")
  }

  const handleEditSocialMedia = () => {
    history.push("/share-profile");
  };
  const handleEditLanguageProfiency = () => {
    history.push("/edit-language-proficiency");
  };
  const handleEditEducation = () => {
    history.push("/edit-education-details");
  };
  const handleEditExperience = () => {
    history.push("/edit-experience-details");
  };

  const [educationalDetails, setEducationalDetails] = useState([]);

  useEffect(() => {
    instance
      .get("educational-details/")
      .then((response) => {
        setEducationalDetails(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }, []);

  const [permanentAddressDetails, setPermanentAddressDetails] = useState([]);

  useEffect(() => {
    instance
      .get("update-address/")
      .then((response) => {
        setPermanentAddressDetails(response.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }, []);

  const [tempAddressDetails, setTempAddressDetails] = useState(null); // Change to null for initial state

  useEffect(() => {
    instance
      .get("update-temp-address/")
      .then((response) => {
        setTempAddressDetails(response.data); 
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);


  const [additionalInfoDetails, setAdditionalInfoDetails] = useState({
    id: null,
    disability: '',
    visa_country: '',
    visa_type: '',
    visa_file: '',
    vf_uploaded_at: '',
    diff_abled_file: '',
    da_uploaded_at: '',
    user: null,
  });

  useEffect(() => {
    instance
      .get("additional-info/")
      .then((response) => {
        setAdditionalInfoDetails(response.data.data.additional_info);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);


  const openDocument = (url) => {
    window.open(url, "_blank");
  };
  
  const [basicInfoDetails, setbasicInfoDetails] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    adhaar_number: "",
    pan_number: "",
    birth_date: "",
    linkedin: "",
    github: "",
    facebook: "",
    behance: "",
    other: "",
    gender: "",
    relationship_status: "",
    profile_picture: ""
  });

  useEffect(() => {
    instance
      .get("basic-info/")
      .then((response) => {
        setbasicInfoDetails(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const socialMediaLinks = {
    linkedin: {
      url: basicInfoDetails.linkedin,
      icon: <FaLinkedin style={{ fontSize: 30, color: '#0077b5' }} />
    },
    github: {
      url: basicInfoDetails.github,
      icon: <FaGithub style={{ fontSize: 30, color: '#333' }} />
    },
    facebook: {
      url: basicInfoDetails.facebook,
      icon: <FaFacebook style={{ fontSize: 30, color: '#4267B2' }} />
    },
    behance: {
      url: basicInfoDetails.behance,
      icon: <FaBehance style={{ fontSize: 30, color: '#1769ff' }} />
    },
    other: {
      url: basicInfoDetails.other,
      icon: <FaUser style={{ fontSize: 30, color: '#262262' }} /> // Default icon for other platforms
    }
  };

  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    instance
      .get("experience-details/")
      .then((response) => {
        setExperiences(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleGenderChange = (event) => {
    setPersonalDetails({ ...personalDetails, gender: event.target.value });
  };


  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);

    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }

    return age;
  };


  const handleCheckboxChange = (event) => {
    setUsePermanentAddress(event.target.checked);
  };



  useEffect(() => {
    instance
    .get('edit-skills/')
      .then(response => {
        setSkills(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the skills!', error);
      });
  }, []);

  const [languageData,setLanguageData] = useState([]);

  useEffect(() => {
    instance
    .get('language-data/')
      .then(response => {
        setLanguageData(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the languages!', error);
      });
  }, []);

  const formatPhoneNumber = (phoneNumber) => {
    // Remove any non-digit characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    // Separate the country code, first part, and second part
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{5})$/);
    if (match) {
      return `(+${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };
  

return (
     <>

    <Container maxWidth= "xs" style={{ marginTop: "30px", marginLeft: "3.3px"}}>
      <ProfileHeader showUploadIcon={false} />
      <Grid container spacing={2} sx={{ mb: "0.2rem" }}>
      <Grid item xs={8} mt={2}>
        <Typography variant="h5" gutterBottom>
          View Profile
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box  mt={1}>
        <FormControl variant="outlined" sx={{ minWidth: '100%', maxWidth: '100%' }}>
  <Select
    id="select"
    defaultValue="/view-profile"
    variant="outlined"
    sx={{
      backgroundColor: '#EFEFF0',
      height: "40px",
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiSelect-icon': {
        top: '50%',
        transform: 'translateY(-50%)',
      },
      width: '100%' // Ensures it takes full width of its container
    }}
    value={selectedOption}
    onChange={handleSelectChange}
  >
    <MenuItem value="profile">Profile</MenuItem>
    <MenuItem value="basic-info">Basic Info</MenuItem>
    <MenuItem value="personal-info">Personal Info</MenuItem>
    <MenuItem value="language-profiency">Language Info</MenuItem>
    <MenuItem value="address-detail">Address Details</MenuItem>
    <MenuItem value="education-info">Education Information</MenuItem>
    <MenuItem value="experience-info">Experience Details</MenuItem>
    <MenuItem value="skill-info">Skills</MenuItem>
    <MenuItem value="document-locker">Document Locker</MenuItem>
  </Select>
</FormControl>

        </Box>
      </Grid>
          
      {(selectedOption === 'profile' || selectedOption === 'basic-info') && (
          <Grid item xs={12}>
            <Box 
              sx={{
                backgroundColor: "#F8F9F9", 
                padding: 2,
                borderRadius: 1
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}}  color="#262262">Basic Information</Typography>
                        <IconButton onClick={handleBasicInfoBtn}>
                          <Edit />
                        </IconButton>
              </Box>
          
              <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                <CustomTypography>Name: </CustomTypography>
                <Typography>{personalDetails.name || ""}</Typography>
              </Box>
          
              <Box display="flex" alignItems="center" mb={0.8}>
                <CustomTypography>Iveri ID:</CustomTypography>
                <Typography>{iveriId || ""}</Typography>
              </Box>
          
              <Box display="flex" alignItems="center" mb={0.8}>
                <CustomTypography>
                  Gender:
                </CustomTypography>
                <Typography>
                  {personalDetails.gender === "M"
                    ? "Male"
                    : personalDetails.gender === "F"
                    ? "Female"
                    : personalDetails.gender === "O"
                    ? "Other"
                    : ""}
                </Typography>
              </Box>
          
              <Box display="flex" alignItems="center" mb={0.8}>
                <CustomTypography>Phone number:</CustomTypography>
                <Typography>{formatPhoneNumber(phoneNumber || "")}</Typography>
              </Box>
          
              <Box display="flex" alignItems="center" mb={0.8}>
                <CustomTypography>Email Id:</CustomTypography>
                <Typography>{email || ""}</Typography>
              </Box>
            </Box>
          </Grid>
     )}

     {(selectedOption === 'profile' || selectedOption === 'personal-info') && (
          <Grid item xs={12}>
            <Box 
              sx={{
                backgroundColor: "#F8F9F9", 
                padding: 2,
                borderRadius: 1
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}}  color="#262262">Personal Information</Typography>
                        <IconButton onClick={handlePersonalInfoBtn}>
                          <Edit />
                        </IconButton>
              </Box>
          
              <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                <CustomTypography>Date of birth: </CustomTypography>
                <Typography>
                  {basicInfoDetails.birth_date
                    ? new Date(basicInfoDetails.birth_date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })
                    : "N.A."}
                </Typography>
              </Box>
          
              <Box display="flex" alignItems="center" mb={0.8}>
                <CustomTypography>Age:</CustomTypography>
                <Typography>{calculateAge(basicInfoDetails.birth_date)}</Typography>
              </Box>
          
              <Box display="flex" alignItems="center" mb={0.8}>
                <CustomTypography>
                  Relationship:
                </CustomTypography>
                <Typography>
                  {basicInfoDetails.relationship_status === "S"
                    ? "Single"
                    : basicInfoDetails.relationship_status === "M"
                    ? "Married"
                    : basicInfoDetails.relationship_status === "D"
                    ? "Divorce"
                    : basicInfoDetails.relationship_status === "W"
                    ? "Widowed"
                    : basicInfoDetails.relationship_status === "H"
                    ? "Homemaker"
                    : ""}
                </Typography>
              </Box>
          
              <Box display="flex" alignItems="center" mb={0.8}>
                <CustomTypography>Hobbies:</CustomTypography>
                <Typography> {hobbies &&
                hobbies.map((hobby) => (
                  <Chip
                    key={hobby}
                    label={hobby}
                    sx={{
                      backgroundColor: "#F8F9F9 !important",
                      fontSize: "15.5px !important",
                      padding: 0, 
                      '& .MuiChip-label': {
                        padding: 0, 
                      }
                    }}
                  />
                ))}</Typography>
              </Box>

        <Box display="flex" alignItems="center" mb={0.8}>
          <Typography sx={{ fontSize: "22px !important", mt: 1.5 }} color="#262262">
            Social Media Links
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" mb={0.8} mt={1}>
          {Object.keys(socialMediaLinks).map((key) => (
            socialMediaLinks[key].url && (
              <Box key={key} component="a" href={socialMediaLinks[key].url} target="_blank" rel="noopener noreferrer" sx={{ mr: 2 }}>
                {socialMediaLinks[key].icon}
              </Box>
            )
          ))}
        </Box>

        <Box display="flex" alignItems="center" mb={0.8}>
          <Typography sx={{ fontSize: "22px !important", mt: 1.5 }} color="#262262">
            Additional Information
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" mt={1} mb={0.8}>
           <CustomTypography>Disability: </CustomTypography>
           <CustomTypography>{additionalInfoDetails.disability.charAt(0).toUpperCase() + additionalInfoDetails.disability.slice(1).toLowerCase()}</CustomTypography>
         </Box>
         <Box 
             display="flex" 
             alignItems="center" 
             justifyContent="space-between" 
             marginY={1.5} 
             onClick={() => openDocument(additionalInfoDetails.diff_abled_file)}
             sx={{ cursor: 'pointer',border: "1.5px solid #262262 !important",
              borderRadius: "5px", padding: "1px 6px !important" }}
           >
             <Typography variant="body1">
               Disability Certificate.pdf
             </Typography>
             <IconButton onClick={() => openDocument(additionalInfoDetails.diff_abled_file)}>
               <MdVisibility color="#262262 !important"/>
             </IconButton>
           </Box>

        <Box display="flex" alignItems="center" mt={1} mb={0.8}>
           <CustomTypography>Visa Type: </CustomTypography>
           <CustomTypography>{additionalInfoDetails.visa_type ? additionalInfoDetails.visa_type : 'N/A'}</CustomTypography>
         </Box>
         <Box 
             display="flex" 
             alignItems="center" 
             justifyContent="space-between" 
             sx={{ cursor: 'pointer',border: "1.5px solid #262262 !important",
              borderRadius: "5px", padding: "1px 6px !important" }}
             marginY={1.5} 
             onClick={() => openDocument(additionalInfoDetails.visa_file)}
             style={{ cursor: 'pointer' }}
           >
             <Typography variant="body1">
               Visa Certificate.pdf
             </Typography>
             <IconButton onClick={() => openDocument(additionalInfoDetails.visa_file)}>
               <MdVisibility color="#262262 !important"/>
             </IconButton>
           </Box>
            </Box>
        </Grid>
          )}
          
          {(selectedOption === 'profile' || selectedOption === 'language-profiency') && (
        <Grid item xs={12}>
      <Box
        sx={{
          backgroundColor: "#F8F9F9",
          borderRadius: 1,
          margin: 0.5 
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ margin: 0.5 }}>
          <Typography sx={{ fontSize: "22px !important", mt: 1.5, fontWeight: "550 !important" }} color="#262262">Language Proficiency</Typography>
          <IconButton onClick={handleEditLanguageProfiency} sx={{ padding: 0.5 }}>
            <Edit />
          </IconButton>
        </Box>

        <Box sx={{ marginTop: 0.5 }}>
          <TableContainer>
            <Table sx={{ borderCollapse: 'collapse' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }} color="#262262">Language</Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }} color="#262262">Read</Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }} color="#262262">Write</Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }} color="#262262">Speak</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {languageData.map((language) => (
                  <TableRow key={language.id}>
                    <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>{language.languageName}</TableCell>
                    <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>{language.readingProficiency}</TableCell>
                    <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>{language.writingProficiency}</TableCell>
                    <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>{language.speakingProficiency}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Grid>
     )}
     
     {(selectedOption === 'profile' || selectedOption === 'address-detail') && (
        <Grid item xs={12}>
            <Box 
              sx={{
                backgroundColor: "#F8F9F9", 
                padding: 2,
                borderRadius: 1
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}}  color="#262262">Address Details</Typography>
                        <IconButton onClick={handleAddressbtn}>
                          <Edit />
                        </IconButton>
              </Box>

              <Box display="flex" alignItems="center" mb={0.8}>
        <Typography sx={{ fontSize: "22px !important"}} color="#262262">
          Temporary Address
        </Typography>
      </Box>
    
      {tempAddressDetails && (
        <>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>House No: </CustomTypography>
            <Typography>{tempAddressDetails.house_no}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Street: </CustomTypography>
            <Typography>{tempAddressDetails.street}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Area: </CustomTypography>
            <Typography>{tempAddressDetails.area}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>City: </CustomTypography>
            <Typography>{tempAddressDetails.city}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>District: </CustomTypography>
            <Typography>{tempAddressDetails.district}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Post Office: </CustomTypography>
            <Typography>{tempAddressDetails.post_office}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Pincode: </CustomTypography>
            <Typography>{tempAddressDetails.pincode}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>State: </CustomTypography>
            <Typography>{tempAddressDetails.state}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Country: </CustomTypography>
            <Typography>{tempAddressDetails.country}</Typography>
          </Box>
        </>
      )}

<Box display="flex" alignItems="center" mt={2} mb={0.8}>
        <FormControlLabel
          control={
            <Checkbox
              checked={usePermanentAddress}
              onChange={handleCheckboxChange}
            />
          }
          label="Same as above"
        />
      </Box>

    
      {!usePermanentAddress && permanentAddressDetails && (
        <>
          <Box display="flex" alignItems="center" mb={0.8}>
            <Typography sx={{ fontSize: "22px !important" }} color="#262262">
              Permanent Address
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>House No: </CustomTypography>
            <Typography>{permanentAddressDetails.house_no}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Street: </CustomTypography>
            <Typography>{permanentAddressDetails.street}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Area: </CustomTypography>
            <Typography>{permanentAddressDetails.area}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>City: </CustomTypography>
            <Typography>{permanentAddressDetails.city}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>District: </CustomTypography>
            <Typography>{permanentAddressDetails.district}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Post Office: </CustomTypography>
            <Typography>{permanentAddressDetails.post_office}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Pincode: </CustomTypography>
            <Typography>{permanentAddressDetails.pincode}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>State: </CustomTypography>
            <Typography>{permanentAddressDetails.state}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Country: </CustomTypography>
            <Typography>{permanentAddressDetails.country}</Typography>
          </Box>
        </>
      )}
              
              </Box>
              </Grid>

         )}
     
           {(selectedOption === 'profile' || selectedOption === 'education-info') && (
        <Grid item xs={12}>
            <Box 
              sx={{
                backgroundColor: "#F8F9F9", 
                padding: 2,
                borderRadius: 1
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}}  color="#262262">Education Information</Typography>
                        <IconButton onClick={handleEditEducation}>
                          <Edit />
                        </IconButton>
              </Box>
               <EducationTimeline />
             </Box> 
            </Grid>
           )}
     
      {(selectedOption === 'profile' || selectedOption === 'experience-info') && (
        <Grid item xs={12}>
            <Box 
              sx={{
                backgroundColor: "#F8F9F9", 
                padding: 2,
                borderRadius: 1
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}}  color="#262262">Experience Details</Typography>
                        <IconButton onClick={handleEditExperience}>
                          <Edit />
                        </IconButton>
              </Box>
               <ExperienceTimeline />
             </Box> 
            </Grid>
                 )}
     
     {(selectedOption === 'profile' || selectedOption === 'skill-info') && (
        <Grid item xs={12}>
            <Box 
              sx={{
                backgroundColor: "#F8F9F9", 
                padding: 2,
                borderRadius: 1
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}}  color="#262262">Skills</Typography>
                        <IconButton onClick={handleSkillBtn}>
                          <Edit />
                        </IconButton>
              </Box>
      <Box display="flex" flexDirection="column">
        {skills.map(skill => (
          <>
         <Typography>
           <Typography component="span" sx={{ fontWeight: 'bold !important', color: "#262262" }}>
             Skills:
           </Typography>
           <Typography component="span" sx={{ fontWeight: 'bold !important', color: "#000000" }}>
             {` ${skill.skill}`}
           </Typography>
         </Typography>

          <Box 
            key={skill.id} 
            display="flex" 
            alignItems="center" 
            justifyContent="space-between" 
            border={1} 
            borderRadius={2} 
            sx={{ padding: "10px !important" }}
            marginY={1.5} 
            onClick={() => openDocument(skill.file_uploaded)}
            style={{ cursor: skill.file_uploaded ? 'pointer' : 'default' }}
          >
            <Typography variant="body1">
              {skill.skill} {skill.file_uploaded ? 'Certificate.pdf' : ''}
            </Typography>
            {skill.file_uploaded && (
              <IconButton onClick={() => openDocument(skill.file_uploaded)}>
                <MdVisibility />
              </IconButton>
            )}
          </Box>
          </>
        ))}
      </Box>
    </Box>
    </Grid>
     )}
     
           {(selectedOption === 'profile' || selectedOption === 'document-locker') && (
          <Grid item xs={12}>
          <Box display="flex" alignItems="center" pb={1} mt={1}>
              <Typography variant="h5" sx={{fontSize: "22px !important",fontWeight: "550 !important"}} color="#262262">Document Locker</Typography>
            </Box>
            <DocumentLocker showImage={true} showTitle={false} />
          </Grid>
           )}

          </Grid>
      </Container>
     </>
  )
}
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import instance from "../../globals/axios.js";

const ExperienceTimelineResume = () => {
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    instance
      .get("experience-details/")
      .then((response) => {
        setExperiences(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: "0 !important",
          padding: "0 !important",
        },
        padding: "0 !important",
      }}
    >
      {experiences.map((experience, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot
              sx={{ backgroundColor: 'black' }} 
            />
       <TimelineConnector />
       </TimelineSeparator>
          <TimelineContent sx={{ margin: "2 !important", padding: "2 !important" }}>
            <Paper
              sx={{
                p: "2 !important",
                backgroundColor: 'transparent',  
                boxShadow: 'none !important',
                border: 'none !important',
              }}
            >

              <Typography sx={{ color: "#414042 !important", fontSize: "16px", fontWeight: "600" }}>
                {`${new Date(experience.start_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })} - ${new Date(experience.end_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}`}
              </Typography>

              <Typography sx={{color: "#414042 !important", fontSize: "16px", fontWeight: "600"}}>
                {experience.company || "N.A."}
              </Typography>
              <Typography sx={{color: "#414042 !important", fontSize: "12px"}}>
                {experience.designation || "N.A."}
              </Typography>

            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default ExperienceTimelineResume;

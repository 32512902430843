import React, { useState } from 'react';
import NavBar from "../../../components/NavBar";
import Layout from "../../../components/Layout";
import { Button, TextField, Typography, Box, Card, Grid, MenuItem, Container } from "@mui/material";
import instance from "../../../globals/axios.js";
import { SERVER_BASE_ADDRESS } from "../../../globals/const";
import { statesData, citiesData } from "./data";
import ProfileHeader from '../../../components/ProfileHeader.js';
import DoneIcon from '@mui/icons-material/Done';
import Sidebar from '../../../components/Sidebar.js';



export default function Address() {
  const [houseNo, setHouseNo] = useState('');
  const [street, setStreet] = useState('');
  const [landmark, setLandmark] = useState('');
  const [area, setArea] = useState('');
  const [city, setCity] = useState('');
  const [postOffice, setPostOffice] = useState('');
  const [district, setDistrict] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [country, setCountry] = useState('India');

  const [selectedState, setSelectedState] = useState('');
  const [availableCities, setAvailableCities] = useState([]);

  const handleStateChange = (state) => {
    setSelectedState(state);
    setAvailableCities(citiesData[state] || []); 
};

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedFormData = new FormData();

    const pincodeRegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;

    if (!pincode.match(pincodeRegex)) {
      alert("Please enter a valid Indian PIN code.");
      return;
    }

    updatedFormData.append('house_no', houseNo);
    updatedFormData.append('street', street);
    updatedFormData.append('landmark', landmark);
    updatedFormData.append('area', area);
    updatedFormData.append('city', city);
    updatedFormData.append('post_office', postOffice);
    updatedFormData.append('district', district);
    updatedFormData.append('state', selectedState);
    updatedFormData.append('pincode', pincode);
    updatedFormData.append('country', country);

    instance.post(SERVER_BASE_ADDRESS + "update-address/", updatedFormData) 
      .then((res) => {
        console.log(res.data);
        alert("Data Saved successfully")
        setTimeout(() => {
          window.location = '/edit-additional-info'; 
        }, 200); 
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  
  return (
    <>
    <Sidebar />
      <Container  style={{ marginTop: '30px' }}>
        <ProfileHeader showUploadIcon={false} />
        <Box display="flex" alignItems="center" pb={1} mt={2}>
              <Typography variant="h6" sx={{ fontWeight: "600" }}>Address</Typography>
              <DoneIcon sx={{ color: 'white', backgroundColor: "#262262", fontSize: "15px", borderRadius: "100%", ml: 1 }} />
            </Box>

      

        <Grid container spacing={2} sx={{ mb: "0.2rem" }}>

        <Grid item xs={12}>
          <Typography sx={{ fontSize: "15px" }} pb={1}>
            House No*
          </Typography>
          <TextField
            value={houseNo}
            onChange={(e) => setHouseNo(e.target.value)}
            variant="outlined"
            required
            fullWidth
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              borderRadius: "5px",
              height: "45px", // Set the height
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
                '& input': {
                  padding: '10px 14px', 
                },
              },
            }}
          />
        </Grid>
    

        <Grid item xs={12}>
          <Typography sx={{ fontSize: "15px" }} pb={1}>
            Street*
          </Typography>
          <TextField
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            variant="outlined"
            required
            fullWidth
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              borderRadius: "5px",
              height: "45px", // Set the height
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
                '& input': {
                  padding: '10px 14px', 
                },
              },
            }}
          />
        </Grid>
    

        <Grid item xs={12}>
          <Typography sx={{ fontSize: "15px" }} pb={1}>
            Landmark*
          </Typography>
          <TextField
            value={landmark}
            onChange={(e) => setLandmark(e.target.value)}
            variant="outlined"
            required
            fullWidth
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              borderRadius: "5px",
              height: "45px", // Set the height
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
                '& input': {
                  padding: '10px 14px', 
                },
              },
            }}
          />
        </Grid>
    
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "15px" }} pb={1}>
            Area*
          </Typography>
          <TextField
                value={area}
                onChange={(e) => setArea(e.target.value)}
            variant="outlined"
            required
            fullWidth
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              borderRadius: "5px",
              height: "45px", // Set the height
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
                '& input': {
                  padding: '10px 14px', 
                },
              },
            }}
          />
        </Grid>
    

        <Grid item xs={12}>
          <Typography sx={{ fontSize: "15px" }} pb={1}>
            Country*
          </Typography>
          <TextField
                value={country}
                onChange={(e) => setCountry(e.target.value)}
            variant="outlined"
            required
            fullWidth
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              borderRadius: "5px",
              height: "45px", // Set the height
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
                '& input': {
                  padding: '10px 14px', 
                },
              },
            }}
          />
        </Grid>
    
    
<Grid item xs={12} md={4}>
  <Typography style={{ marginTop: "6px" }} pb={1}>State</Typography>
  <TextField
    select
    required
    size="small"
    value={selectedState}
    onChange={(e) => handleStateChange(e.target.value)}
    sx={{
      backgroundColor: "rgba(214,214,214, 0.3)",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "none",
        },
      },
      height: "45px",
      borderRadius: "5px",
    }}
    fullWidth
  >
    {statesData.map((state, index) => (
      <MenuItem key={index} value={state}>
        {state}
      </MenuItem>
    ))}
  </TextField>
</Grid>


<Grid item xs={12} md={4}>
  <Typography color="black" style={{ marginTop: "6px"}} pb={1}>City</Typography>
  <TextField
    select
    required
    size="small"
    value={city}
    onChange={(e) => setCity(e.target.value)}
    sx={{
      backgroundColor: "rgba(214,214,214, 0.3)",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "none",
        },
      },
      height: "45px",
      borderRadius: "5px",
    }}
    fullWidth
  >
    {availableCities.map((city, index) => (
      <MenuItem key={index} value={city}>
        {city}
      </MenuItem>
    ))}
  </TextField>
</Grid>


<Grid item xs={12} md={4}>
  <Typography color="black" style={{ marginTop: "6px" }} pb={1}>District</Typography>
  <TextField
    label="District"
    variant="outlined"
    name="district"
    value={district}
    onChange={(e) => setDistrict(e.target.value)}
    required
    fullWidth
    size="small"
    sx={{
      backgroundColor: "rgba(214,214,214, 0.3)",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "none",
        },
      },
      height: "45px",
      borderRadius: "5px",
    }}
  />
</Grid>

    <Grid item xs={12} md={4}>
    <Typography color="black" style={{ marginTop: "8px" }} pb={1}>Post Office</Typography>
    <TextField
        variant="outlined"
        value={postOffice}
        onChange={(e) => setPostOffice(e.target.value)}
        required
        fullWidth
        sx={{
          backgroundColor: "rgba(214,214,214, 0.3)",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
          },
          height: "45px",
          borderRadius: "5px",
        }}
        size="small"
    />
</Grid>
    
        <Grid item xs={12} md={4}>
            <Typography color="black" style={{ marginTop: "8px" }} pb={1}>Pincode</Typography>
            <TextField
                variant="outlined"
                name="pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                required
                fullWidth
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  height: "45px",
                  borderRadius: "5px",
                }}
                size="small"
                onKeyPress={(event) => {
                  const charCode = event.charCode;
                  if (!(charCode >= 48 && charCode <= 57)) {
                    event.preventDefault();
                  }
                }}
            />
        </Grid>
    
        
    </Grid>
    
    <Grid
      container
      justifyContent="center"
    >
    <Grid xs={12} sm={6} mt={2}>
          <Button
            variant="outlined"
            fullWidth
            sx={{border: "1px solid #272260", color: "black"}}
            onClick={() => window.location.href = '/edit-temp-address'} // Redirect on click
          >
            Add Temporary Address
          </Button>
        </Grid>
    </Grid>

    <Grid
      container
      justifyContent="center"
      my={3}
    >
        <Grid xs={12} sm={6}>
          <Button
              variant="contained"
              onClick={handleSubmit}
              fullWidth
              sx={{backgroundColor: "#272260"}}
          >
              SAVE
          </Button>
</Grid>
        </Grid>

        
      </Container>
    </>
  );
}
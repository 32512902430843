import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import NavBar from "../../../components/NavBar";
import Layout from "../../../components/Layout";
import {
  Button,
  Container,
  Typography,
  Grid,
  TextField,
  Select,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  MenuItem,
  Autocomplete,
  Chip,
  IconButton,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import PhoneInput from "react-phone-input-2";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useHistory } from "react-router-dom";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./DatePicker.css";

import { SERVER_BASE_ADDRESS } from "../../../globals/const";
import instance from "../../../globals/axios.js";
import axios from "axios";
import dayjs from "dayjs";
import { FaLinkedin, FaBehance, FaGithub, FaFacebook, FaUser } from "react-icons/fa";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import ProfileHeader from "../../../components/ProfileHeader.js";
import Sidebar from "../../../components/Sidebar.js";
import CustomButton from "../../../components/Button/CustomButtom.js";

const styles = {
  customDatePicker: {
    margin: "10px",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
};

export default function BasicInfo() {
  const [isSaved, setIsSaved] = useState(false);
  const [family, setFamily] = useState([{ name: "", age: "", occupation: "" }]);
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    adhaar_number: "",
    pan_number: "",
    birth_date: null,
    gender: "",
    relationship_status: "",
    family: [{ relation: "", name: "", age: "", occupation: "" }],
    hobbies: [],
    linkedin: "",
    github: "",
    facebook: "",
    behance: "",
    other: "",
    videoFile: null,
    aadhar_file: null,
    pan_file: null,
    profilePic: null,
  });
  const [newHobby, setNewHobby] = useState("");
  const [socialMedia, setSocialMedia] = useState("");
  const [link, setLink] = useState("");
  const [links, setLinks] = useState([]);
  const [isAddingLink, setIsAddingLink] = useState(false);
   const history = useHistory();

  const hobbiesArray = [
    "Reading",
    "Painting",
    "Cooking",
    "Photography",
    "Hiking",
    "Gardening",
    "Playing Guitar",
    "Swimming",
    "Dancing",
    "Traveling",
    "Yoga",
    "Fishing",
    "Singing",
    "Chess",
    "Cycling",
    "Writing",
    "Knitting",
    "Watching Movies",
    "Playing Tennis",
    "Collecting Stamps",
    "Mountain Climbing",
    "Skydiving",
    "Scuba Diving",
    "Bird Watching",
    "Pottery",
    "Woodworking",
    "Meditation",
    "Volunteering",
    "Archery",
    "Cooking",
    "Skiing",
    "Baking",
    "Camping",
    "Running",
    "Drawing",
    "Puzzle Solving",
    "Sculpting",
    "Golfing",
    "Surfing",
    "Sewing",
    "Horseback Riding",
    "Ice Skating",
    "Rowing",
    "Bowling",
    "Billiards",
    "Model Building",
    "Calligraphy",
    "Astronomy",
    "Collecting Coins",
  ];

  const updateFormData = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleMobileNumberChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      mobile_number: value,
    }));
  };

  const handleBirthDateChange = (date) => {
    if (date) {
      const selectedDate = dayjs(date).toDate(); // Convert to Date object
      setFormData((prevData) => ({
        ...prevData,
        birth_date: selectedDate ? dayjs(selectedDate).format("YYYY-MM-DD") : null,
      }));
    }
  };

  const clearBirthDate = () => {
    setFormData((prevData) => ({
      ...prevData,
      birth_date: null,
    }));
  };

  const handleGenderChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      gender: event.target.value,
    }));
  };

  const handleRelationshipChange = (event) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      relationship_status: value,
    }));
  };

  const handleFamilyChange = (index, field, value) => {
    const updatedFamily = [...formData.family];
    updatedFamily[index][field] = value;
    setFormData((prevData) => ({
      ...prevData,
      family: updatedFamily,
    }));
  };

  const handleAddFamilyMember = () => {
    setFormData((prevData) => ({
      ...prevData,
      family: [...prevData.family, { relation: "", name: "", age: "", occupation: "" }],
    }));
  };

  const handleRemoveFamilyMember = (index) => {
    const updatedFamily = [...formData.family];
    updatedFamily.splice(index, 1);
    setFormData((prevData) => ({
      ...prevData,
      family: updatedFamily,
    }));
  };

  const handleHobbiesChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      hobbies: [...prevData.hobbies, ...newValue],
    }));
  };

  const handleLogoClick = (logo) => {
    setSocialMedia(logo);
    setLink("");
    setIsAddingLink(true);
  };

  const getIconStyle = (icon, selectedIcon) => ({
    cursor: "pointer",
    marginRight: "10px", // Adjust the margin as needed
    color: icon === selectedIcon ? "blue" : "gray", // Highlight the selected icon
  });

  const handleAddHobby = () => {
    if (newHobby && !formData.hobbies.includes(newHobby)) {
      setFormData((prevData) => ({
        ...prevData,
        hobbies: [...prevData.hobbies, newHobby],
      }));
      setNewHobby("");
    }
  };

  const handleRemoveHobby = (hobbyToRemove) => {
    setFormData((prevData) => ({
      ...prevData,
      hobbies: prevData.hobbies.filter((hobby) => hobby !== hobbyToRemove),
    }));
  };

  const handleVideoFileChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      videoFile: event.target.files[0],
    }));
  };

  useEffect(() => {
    instance
      .get("basic-info/")
      .then((res) => {
        const basicInfoData = res.data;
        updateFormData(basicInfoData);
      })
      .catch((error) => {
        console.error("Error fetching basic info:", error);
      });
  }, []);

  const [aadharFile, setAadharFile] = useState(null);
  const [panFile, setPanFile] = useState(null);

  const onAdhaarFile = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setAadharFile(acceptedFiles[0]);
      setIsAadharFileUploaded(true); // Set the state to true when a file is uploaded
    }
  };

  const onPanFile = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setPanFile(acceptedFiles[0]);
      setIsPanFileUploaded(true); // Set the state to true when a file is uploaded
    }
  };

  const [isAadharFileUploaded, setIsAadharFileUploaded] = useState(false);
  const [isPanFileUploaded, setIsPanFileUploaded] = useState(false);
  const dropzoneStyle = {
    border: "2px dashed #ccc",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
  };

  const { getRootProps: getAadharRootProps, getInputProps: getAadharInputProps } = useDropzone({
    onDrop: onAdhaarFile,
    accept: ".pdf, .jpeg, .jpg, .png",
  });

  // Use the useDropzone hook for Pan File drop zone
  const { getRootProps: getPanRootProps, getInputProps: getPanInputProps } = useDropzone({
    onDrop: onPanFile,
    accept: ".pdf, .jpeg, .jpg, .png",
  });

  const [thobbies, setTHobbies] = useState(["Reading", "Painting", "Cooking"]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formDataToSend = new FormData();

    formDataToSend.append("first_name", formData.first_name);
    formDataToSend.append("middle_name", formData.middle_name);
    formDataToSend.append("last_name", formData.last_name);
    formDataToSend.append("mobile_number", formData.mobile_number);
    formDataToSend.append("email", formData.email);

    console.log(formData.adhaar_number);
    console.log(formData.pan_number);

    if (
      formData.adhaar_number !== "" &&
      formData.adhaar_number !== null &&
      formData.adhaar_number !== "null"
    ) {
      if (!/^\d{12}$/.test(formData.adhaar_number)) {
        alert("Aadhar number must be 12 digits");
        return;
      }
    }

    if (
      formData.pan_number !== "" &&
      formData.pan_number !== null &&
      formData.pan_number !== "null"
    ) {
      if (!/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(formData.pan_number)) {
        alert("Invalid PAN card number");
        return;
      }
    }

    formDataToSend.append("adhaar_number", formData.adhaar_number);
    formDataToSend.append("pan_number", formData.pan_number);

    if (formData.gender !== null && formData.gender !== "") {
      formDataToSend.append("gender", formData.gender);
    }

    if (formData.relationship_status !== null && formData.relationship_status !== "") {
      formDataToSend.append("relationship_status", formData.relationship_status);
    }

    formDataToSend.append("family", formData.family);

    // const isValidURL = (url) => /^(http|https):\/\/[^\s$.?#].[^\s]*$/i.test(url);

    const isValidURL = (url) => {
      const pattern =
        /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
      return pattern.test(url);
    };

    if (formData.linkedin !== null && formData.linkedin !== "" && formData.linkedin !== "null") {
      const linkedinIsValid = isValidURL(formData.linkedin);
      if (linkedinIsValid) {
        formDataToSend.append("linkedin", formData.linkedin);
      } else {
        alert("LinkedIn link is invalid.");
        return;
      }
    }

    if (formData.github !== null && formData.github !== "" && formData.github !== "null") {
      console.log("git link", formData.facebook);

      const githubIsValid = isValidURL(formData.github);
      if (githubIsValid) {
        formDataToSend.append("github", formData.github);
      } else {
        alert("GitHub link is invalid.");
        return;
      }
    }

    // Validate and append Facebook link
    if (formData.facebook !== null && formData.facebook !== "" && formData.facebook !== "null") {
      const facebookIsValid = isValidURL(formData.facebook);
      console.log("link", formData.facebook);
      if (facebookIsValid) {
        formDataToSend.append("facebook", formData.facebook);
      } else {
        alert("Facebook link is invalid.");
        return;
      }
    }

    // Validate and append Behance link
    if (formData.behance !== null && formData.behance !== "" && formData.behance !== "null") {
      const behanceIsValid = isValidURL(formData.behance);
      if (behanceIsValid) {
        formDataToSend.append("behance", formData.behance);
      } else {
        alert("Behance link is invalid.");
        return;
      }
    }

    // Validate and append Other link
    if (formData.other !== null && formData.other !== "" && formData.other !== "null") {
      const otherIsValid = isValidURL(formData.other);
      if (otherIsValid) {
        formDataToSend.append("other", formData.other);
      } else {
        alert("Other link is invalid.");
        return;
      }
    }

    formDataToSend.append("videoFile", formData.videoFile);

    if (formData.birth_date) {
      formDataToSend.append("birth_date", formData.birth_date);
    }

    // Include hobbies if provided
    if (formData.hobbies.length > 0) {
      formDataToSend.append("hobbies", formData.hobbies);
    }

    if (aadharFile) {
      formDataToSend.append("aadhar_file", aadharFile);
    }
    if (panFile) {
      formDataToSend.append("pan_file", panFile);
    }
    console.log(formDataToSend);

    instance
      .post(SERVER_BASE_ADDRESS + "basic-info/", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
        setIsSaved(true);
        alert("Data Saved Successfully");
        setTimeout(() => {
          window.location = "/personal-details-info";
        }, 200);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    if (!(charCode >= 65 && charCode <= 90) && !(charCode >= 97 && charCode <= 122)) {
      event.preventDefault();
    }
  };

  const handleNextClick = () => {
    history.push("/personal-details-info")
  };


  const handleAuthorizeClick = async () => {
    try {
      // -- To change
      const callbackUrl =
        "https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize?response_type=code&client_id=UZE095AD36&state=oidc_flow&redirect_uri=http%3A%2F%2F127.0.0.1%3A8000%2Fapi%2Fdigilocker-callback%2F&code_challenge=F49-7ddV6d_E2wv4wCb5EHG8HApHeNNLzC0pDEpfxVw&code_challenge_method=S256&dl_flow=signin&acr=driving_licence+pan+aadhaar+email+mobile+user_alias+opuser_alias+pin&amr=all+driving_licence+aadhaar+pan&pla=Y&scope=address+careof+picture+openid";
      // const callbackUrl = 'https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize?response_type=code&client_id=HYD95FECA5&state=oidc_flow&redirect_uri=https%3A%2F%2Fiverify-uat.newrl.net%2Fapi%2Fdigilocker-callback%2F&code_challenge=dA2tDnSAJGnpaQT6zNHM8CmwgezsPr9Rjz8sm_TnXEE&code_challenge_method=S256&dl_flow=signin&acr=driving_licence+pan+aadhaar+email+mobile+user_alias+opuser_alias+pin&amr=all+driving_licence+aadhaar+pan+exists_ac_pin&pla=Y&scope=address+careof+picture+openid'
      window.location.href = callbackUrl;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [accessToken, setAccessToken] = useState(null);
  const [scope, setScope] = useState(null);
  const [consentValidTill, setConsentValidTill] = useState(null);

  const [showFetchDocs, setShowFetchDocs] = useState(false);
  const [aadhaarFetched, setAadhaarFetched] = useState(false);
  const [panFetched, setPanFetched] = useState(false);
  const [showFetchStatus, setShowFetchStatus] = useState(false);
  const [fetchDocumentsData, setFetchDocumentsData] = useState(null);

  // const getAccessToken = async () => {
  //   try {
  //     const response = await axios.post(
  //       'https://digilocker.meripehchaan.gov.in/public/oauth2/2/token',
  //       new URLSearchParams({
  //         code: authCode,
  //         grant_type: 'authorization_code',
  //         client_id: 'UZE095AD36',
  //         client_secret: '74d78cbefd83b248dddb',
  //         redirect_uri: 'http://127.0.0.1:8000/api/digilocker-callback/',
  //         code_verifier: '1lgPCo8GjPOaaAieeim9Rw5sFz8hYQrhed',
  //       }),
  //       {
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //         },

  //       }
  //     );
  //     setAccessToken(response.data.access_token)
  //     setShowFetchDocs(true)
  //     setConsentValidTill(response.data.consent_valid_till)
  //     setScope(response.data.scope)

  //   } catch (error) {
  //     console.error('Error getting access token:', error);
  //     throw error;
  //   }
  // };

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(
        "https://digilocker.meripehchaan.gov.in/public/oauth2/2/files/issued",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setFetchDocumentsData(response.data.items);
      const hasAadhaar = response.data.items.some((item) => item.name === "Aadhaar Card");
      setAadhaarFetched(hasAadhaar);
      const hasPan = response.data.items.some((item) => item.name === "PAN Verification Record");
      setPanFetched(hasPan);

      const response1 = await axios.get(
        "https://digilocker.meripehchaan.gov.in/public/oauth2/3/xml/eaadhaar",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Eadhaar response", response1);

      // const hasAadhaar = response.data.items.some(item => item.name === 'Aadhaar Card');
      // setAadhaarFetched(hasAadhaar);

      setShowFetchStatus(true);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    sessionStorage.setItem("digilocker_code", code);
    sessionStorage.setItem("digilocker_state", state);

    const storedCode = sessionStorage.getItem("digilocker_code");

    if (code) {
      // const clientId = process.env.DIGI_CODE_CLIENT_ID;
      // const clientSecret = process.env.DIGI_CODE_CLIENT_SECRET;
      // const codeVerifier = process.env.DIGI_CODE_VERIFIER;

      // -- To change

      // for Dev env
      // code: storedCode,
      // grant_type: 'authorization_code',
      // client_id: 'UZE095AD36',
      // client_secret: '74d78cbefd83b248dddb',
      // redirect_uri: 'http://127.0.0.1:8000/api/digilocker-callback/',
      // code_verifier: '1lgPCo8GjPOaaAieeim9Rw5sFz8hYQrhed',

      // for Testing env
      // code: storedCode,
      // grant_type: 'authorization_code',
      // client_id: 'HYD95FECA5',
      // client_secret: '87a2fc29c6fd3100f9cb',
      // redirect_uri: 'https://iverify-uat.newrl.net/api/digilocker-callback/',
      // code_verifier: 'xMoRD7eccHB5s-JkFfCaq-trjWlElUu13YhpiJcSu~ogGW8r69WdqRuhD1KLWPho7f5mXQO08nhYXPD0HHi_bR',

      const digilockerCode = sessionStorage.getItem("digilocker_code");

      if (digilockerCode !== null || digilockerCode !== undefined || digilockerCode !== "null") {
        setShowFetchDocs(true);
      }

      const params = new URLSearchParams({
        code: storedCode,
        grant_type: "authorization_code",
        client_id: "UZE095AD36",
        client_secret: "74d78cbefd83b248dddb",
        redirect_uri: "http://127.0.0.1:8000/api/digilocker-callback/",
        code_verifier: "0TVKszwWD.8mX1MtPq7AgDyolBdxXUKgFkvCO95i25.uKNYcDxXKsHrmMZdbPkCQDzHn3WN7",
      });

      // const params = new URLSearchParams({
      //   code: storedCode,
      //   grant_type: 'authorization_code',
      //   client_id: clientId,
      //   client_secret: clientSecret,
      //   redirect_uri: 'https://iverify-uat.newrl.net/api/digilocker-callback/',
      //   code_verifier: codeVerifier,
      // });

      axios
        .post("https://digilocker.meripehchaan.gov.in/public/oauth2/2/token", params.toString(), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          setAccessToken(response.data.access_token);
          setShowFetchDocs(true);
          setConsentValidTill(response.data.consent_valid_till);
          setScope(response.data.scope);
        })
        .catch((error) => {
          console.error("Error getting access token:", error);
        });
    }
  }, []);

  return (
    <>
      <Sidebar />
      <Container maxWidth="xs" style={{ marginTop: "30px" }}>
        <Container>
          <ProfileHeader showUploadIcon={true} />
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Basic Information
            </Typography>
          </Grid>
          <Grid container spacing={2} mt={0.25}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "15px" }} pb={1}>
                First Name <span style={{ color: "#FFA500", fontSize: "15px" }}>🟠</span>
              </Typography>
              <TextField
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                placeholder="First Name"
                required
                size="small"
                fullWidth
                onKeyPress={handleKeyPress}
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  borderRadius: "5px",
                  height: "45px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                    "& input": {
                      padding: "10px 14px", // Adjust padding to fit height
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "15px" }} pb={1}>
                Middle Name <span style={{ color: "#FFA500", fontSize: "15px" }}>🟠</span>
              </Typography>
              <TextField
                name="middle_name"
                value={formData.middle_name === "null" ? null : formData.middle_name}
                placeholder="Middle name"
                onChange={handleInputChange}
                size="small"
                fullWidth
                onKeyPress={handleKeyPress}
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  borderRadius: "5px",
                  height: "45px", // Set the height
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                    "& input": {
                      padding: "10px 14px", // Adjust padding to fit height
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "15px" }} pb={1}>
                Last Name <span style={{ color: "#FFA500", fontSize: "15px" }}>🟠</span>
              </Typography>
              <TextField
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                placeholder="Last name"
                required
                size="small"
                fullWidth
                onKeyPress={handleKeyPress}
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  borderRadius: "5px",
                  height: "45px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                    "& input": {
                      padding: "10px 14px", // Adjust padding to fit height
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={0.25}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" pb={1}>
                <Typography sx={{ fontSize: "15px" }}>Mobile Number</Typography>
                <DoneIcon
                  sx={{
                    color: "white",
                    backgroundColor: "#262262",
                    fontSize: "15px",
                    borderRadius: "100%",
                    ml: 1,
                  }}
                />
              </Box>
              <PhoneInput
                country={"in"}
                required
                value={formData.mobile_number}
                onChange={handleMobileNumberChange}
                inputStyle={{
                  width: "100%",
                  fontSize: "14px",
                  height: "45px",
                  backgroundColor: "rgba(214,214,214, 0.3)",
                }}
                parseCharacters={(value) => value.replace(/\D/g, "")}
                displayInitialValueAsLocalNumber
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                sx={{
                  color: "#272260",
                  border: "0.5px solid #272260",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "white",
                    border: "1px solid #666671",
                    boxShadow: "none",
                  },
                  "&:active": {
                    backgroundColor: "white",
                    border: "1px solid #666671",
                    boxShadow: "none",
                  },
                }}>
                Add other Number
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={0.25}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" pb={1}>
                <Typography sx={{ fontSize: "15px" }}>Email Address</Typography>
                <DoneIcon
                  sx={{
                    color: "white",
                    backgroundColor: "#262262",
                    fontSize: "15px",
                    borderRadius: "100%",
                    ml: 1,
                  }}
                />
              </Box>
              <TextField
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                fullWidth
                disabled
                sx={{ backgroundColor: "rgba(214,214,214, 0.3)" }}
                placeholder="Email address"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                sx={{ color: "#272260", border: "0.5px solid #272260", width: "100%" }}>
                Add other Email
              </Button>
            </Grid>
          </Grid>

          {/* Digiocker version Input */}

          <Box>
            <Button
              endIcon={<LockPersonIcon />}
              sx={{ mt: 2, mb: 1 }}
              fullWidth
              onClick={handleAuthorizeClick}
              variant="outlined"
              color="primary">
              Authorize Digilocker
            </Button>
            <Typography mx={2} variant="body1">
              <em> Adhaar Card and PAN card details will be fetch via Digilocker</em>
            </Typography>
            {showFetchDocs && (
              <Button
                sx={{ my: 2 }}
                fullWidth
                onClick={fetchDocuments}
                variant="contained"
                color="success">
                Fetch Documents
              </Button>
            )}
          </Box>

          {showFetchStatus && (
            <Stack mt={3} direction="column" spacing={2}>
              <Alert fullWidth severity={aadhaarFetched ? "success" : "error"}>
                <Typography variant="body1" component="div">
                  {aadhaarFetched
                    ? "Aadhaar Card Fetched Successfully"
                    : "Error Fetching Aadhaar Details"}
                </Typography>
              </Alert>

              <Alert fullWidth severity={panFetched ? "success" : "error"}>
                <Typography variant="body1" component="div">
                  {panFetched ? "PAN Card Fetched Successfully" : "Error Fetching PAN Details"}
                </Typography>
              </Alert>
            </Stack>
          )}

          <Grid item xs={8} mx="auto" my={2}>
            <CustomButton onClick={handleSubmit}>{ "Next"}</CustomButton>

          </Grid>
          <Grid item xs={8} mx="auto" mb={4}>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleNextClick}
              sx={{ border: "1px solid #272260", color: "#272260" }}>
              Back
            </Button>
          </Grid>
        </Container>
      </Container>
    </>
  );
}

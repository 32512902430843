import React, { useState } from "react";
import NavBar from "../../../components/NavBar";
import Layout from "../../../components/Layout";
import { Button, Grid, Typography, TextField, Box, Card, Select, FormControlLabel, Checkbox, MenuItem, Container, FormControl, InputLabel} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faLink } from "@fortawesome/free-solid-svg-icons";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import { styled, makeStyles } from '@mui/system';
import ProfileHeader from "../../../components/ProfileHeader";
import DoneIcon from '@mui/icons-material/Done';
import Sidebar from "../../../components/Sidebar";
import CustomAccordian from "../../../components/CustomAccordian";

import instance from "../../../globals/axios";

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100px',
  width: '100%',
  cursor: 'pointer',
  padding: '8px',
  margin: '16px 0',
};

const fileContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #cccccc',
  padding: '8px',
  margin: '8px 0',
};

export default function ExperienceDetails() {

  const [currentPage, setCurrentPage] = useState(1);

  // State variables for form inputs
  const [company, setCompany] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fixedSalaryCurrency, setFixedSalaryCurrency] = useState('');
  const [fixedSalaryAmount, setFixedSalaryAmount] = useState('');
  const [variableSalaryCurrency, setVariableSalaryCurrency] = useState('');
  const [variableSalaryAmount, setVariableSalaryAmount] = useState('');
  const [accommodation, setAccommodation] = useState('');
  const [accommodationProvided, setAccommodationProvided] = useState(false);

  const [accommodationFurniture, setAccommodationFurniture] = useState('');
  const [accommodationFamily, setAccommodationFamily] = useState('');

  const [perks, setPerks] = useState('');
  const [designation, setDesignation] = useState('');
  const [reportingTo, setReportingTo] = useState('');
  const [reportee, setReportee] = useState('');
  const [role, setRole] = useState('');
  const [workLocation, setWorkLocation] = useState('');
  const [officeType, setOfficeType] = useState('');
  const [noticePeriod, setNoticePeriod] = useState('');
  const [daysNegotiable, setDaysNegotiable] = useState('');
  const [currency, setCurrency] = useState('');
  const [amountInLakhs, setAmountInLakhs] = useState('');
  const [briefRole, setBriefRole] = useState('');
  const [currentlyWorking, setCurrentlyWorking] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);
  };

  const handleUpload = () => {
    if (selectedFile && selectedFileName) {
      setUploadedFiles([...uploadedFiles, { file: selectedFile, fileName: selectedFileName }]);
      setSelectedFile(null);
      setSelectedFileName('');
    }
  };

  const handleRemove = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*',
  });

  const [kraFormData, setKraFormData] = useState([
    {
      kra: "",
      achievement: "",
    },
  ]);

  const handleKraChange = (event, index) => {
    const { name, value } = event.target;
    const updatedKraFormData = [...kraFormData];
    updatedKraFormData[index][name] = value;
    setKraFormData(updatedKraFormData);
  };

  const handleAddBoxKra = () => {
    setKraFormData([
      ...kraFormData,
      {
        kra: "",
        achievement: "",
      },
    ]);
  };

  const handleRemoveBoxKra = (index) => {
    const updatedKraFormData = [...kraFormData];
    updatedKraFormData.splice(index, 1);
    setKraFormData(updatedKraFormData);
  };




  const handleSaveClick = () => {
    
    const formData = new FormData();
    formData.append('company', company);
    formData.append('company_description', companyDescription);

    const isValidURL = (url) => {
      const pattern = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
      return pattern.test(url);
    };

    if (website !== null && website !== "" && website !== "null") {
      const isValid = isValidURL(website);
      if (isValid) {
        formData.append('website', website);
      } else {
        alert('Website link is invalid.');
        return;
      }
    }
    else{
      alert('Please add company website.');
      return;
    }

    const currentDate = new Date();

    if (!startDate) {
      alert('Start Date cannot be null.');
      return;
    }    

    if (currentlyWorking) {
      formData.append('start_date', startDate.toISOString());
      formData.append('end_date', currentDate.toISOString()); 
    } 
    else{

      if (endDate > startDate) {
        formData.append('start_date', startDate.toISOString());
        formData.append('end_date', endDate.toISOString());
      } 
      else {
        alert('End Date must be later than Start Date.');
        return;
      }
    
    }

    formData.append('fixed_salary_currency', fixedSalaryCurrency);
    formData.append('fixed_salary_amount', fixedSalaryAmount);
    formData.append('variable_salary_currency', variableSalaryCurrency);
    formData.append('variable_salary_amount', variableSalaryAmount);
    formData.append('accommodation', accommodation);
    formData.append('accommodation_furniture', accommodationFurniture);
    formData.append('accommodation_family', accommodationFamily);
    formData.append('perks', perks);
    formData.append('designation', designation);
    formData.append('reporting_to', reportingTo);
    formData.append('reportee', reportee);
    formData.append('role', role);
    formData.append('work_location', workLocation);
    formData.append('office_type', officeType);
    formData.append('notice_period', noticePeriod);
    formData.append('days_negotiable', daysNegotiable);
    formData.append('currency', currency);
    formData.append('expected_salary', amountInLakhs);
    formData.append('brief_role', briefRole);
    formData.append('currently_working', currentlyWorking);
    const formattedKraData = kraFormData.map((item) => {
      return {
        kra: item.kra,
        achievement: item.achievement,
      };
    });

    formData.append("kra_data", JSON.stringify(formattedKraData));


    instance.post('experience-details/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      const experience_details_id = response.data.data.experience_details_id;

      const uploadFormData = new FormData();
      uploadFormData.append('experience_details_id', experience_details_id);
      uploadedFiles.forEach((fileData, index) => {
        uploadFormData.append(`file_names_${index}`, fileData.fileName);
        uploadFormData.append(`files_${index}`, fileData.file);
      });
      uploadFormData.append('experience_details_id', experience_details_id);

      instance.post('upload-experience-details/', uploadFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((uploadResponse) => {
        alert('Data saved and uploaded successfully');
        // setTimeout(() => {
        //   window.location = "/edit-skills";
        // }, 200);
      })
      .catch((uploadError) => {
        console.error('Error uploading data:', uploadError.message);
      });
    })
    .catch((error) => {
      alert('Error saving data: ' + error.message);
    });
  };


  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    
  };




  return (
    <>
    <Sidebar />
      <Container style={{ marginTop: '30px' }} mb={2}>
      <ProfileHeader showUploadIcon={false} />
      <Grid sx={{ textAlign:"center"}} item xs={12} my={1}>
      <Box display="flex" alignItems="center" pb={1} mt={2} mb={3}>
              <Typography variant="h6" sx={{ fontWeight: "600" }}>Experience Details</Typography>
              <DoneIcon sx={{ color: 'white', backgroundColor: "#262262", fontSize: "15px", borderRadius: "100%", ml: 1 }} />
            </Box>
            </Grid>


  <Container my={3}>
   <Grid container mb={6} spacing={2}>
      <CustomAccordian title="Company Details">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "15px" }} pb={1}>
              Company Name
            </Typography>
            <TextField
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              variant="outlined"
              required
              fullWidth
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                borderRadius: "5px",
                height: "45px",
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                  '& input': {
                    padding: '10px 14px',
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ fontSize: "15px" }} pb={1}>
              Company Description
            </Typography>
            <TextField
              value={companyDescription}
              onChange={(e) => setCompanyDescription(e.target.value)}
              variant="outlined"
              required
              fullWidth
              placeholder="Description"
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                borderRadius: "5px",
                height: "45px",
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                  '& input': {
                    padding: '10px 14px',
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ fontSize: "15px" }} pb={1}>
              Website
            </Typography>
            <TextField
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              variant="outlined"
              required
              fullWidth
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                borderRadius: "5px",
                height: "45px",
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                  '& input': {
                    padding: '10px 14px',
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ fontSize: "15px" }} pb={1}>
              Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  borderRadius: "5px",
                  width: "100%",
                  height: "45px",
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                    '& input': {
                      padding: '10px 14px',
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} my={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={endDate}
                onChange={(newValue) => { setEndDate(newValue); }}
                disabled={currentlyWorking}
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  borderRadius: "5px",
                  height: "45px",
                  width: "100%",
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                    '& input': {
                      padding: '10px 14px',
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <label>
              <input
                type="checkbox"
                checked={currentlyWorking}
                onChange={(event) => {
                  setCurrentlyWorking(event.target.checked);
                  if (event.target.checked) {
                    setEndDate(null);
                  }
                }}
              />
              Currently Working
            </label>
          </Grid>
        </Grid>
      </CustomAccordian>

      <CustomAccordian title="Latest Salary and Benefits Details">
      <Grid container spacing={2}>

            
            <Grid item xs={12}>
              <Typography  sx={{ fontSize: "17px" }} pb={1}>Fixed Salary</Typography>
              <Grid item xs={12} md={6}>
  <FormControl fullWidth>
    <Select
      fullWidth
      placeholder="Currency"
      variant="outlined"
      value={fixedSalaryCurrency}
      onChange={(e) => setFixedSalaryCurrency(e.target.value)}
      sx={{
        backgroundColor: "rgba(214,214,214, 0.3)",
        height: "45px",
        borderRadius: "5px",
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
    >
      <MenuItem value="INR">INR</MenuItem>
      <MenuItem value="USD">USD</MenuItem>
    </Select>
  </FormControl>
</Grid>

            </Grid>


            <Grid item xs={12}>
            <Typography  sx={{ fontSize: "15px" }} pb={1}>Amount</Typography>
              <TextField
                fullWidth
                placeholder="Amount"
                variant="outlined"
                onKeyPress={(event) => {
                  const charCode = event.charCode;
                  if (!(charCode >= 48 && charCode <= 57)) {
                    event.preventDefault();
                  }
                }}
                value={fixedSalaryAmount}
                onChange={(e) => setFixedSalaryAmount(e.target.value)}
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  borderRadius: "5px",
                  height: "45px",
                  width: "100%",
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                    '& input': {
                      padding: '10px 14px',
                    },
                  },
                }}
              />
            </Grid>
            
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "17px" }} pb={1}>Variable Salary</Typography>
            <FormControl fullWidth>
              <Select
                fullWidth
                variant="outlined"
                labelId="variable-salary-currency-label"
                id="variable-salary-currency"
                label="Currency"
                value={variableSalaryCurrency}
                onChange={(e) => setVariableSalaryCurrency(e.target.value)}
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  height: "45px",
                  borderRadius: "5px",
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              >
                <MenuItem value="INR">INR</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
          <Typography  sx={{ fontSize: "15px" }} pb={1}>Amount</Typography>
            <TextField 
            fullWidth 
            variant="outlined"  
            placeholder="Amount"
            onKeyPress={(event) => {
            const charCode = event.charCode;
            if (!(charCode >= 48 && charCode <= 57)) {
              event.preventDefault();
            }
          }} value={variableSalaryAmount} onChange={(e) => setVariableSalaryAmount(e.target.value)} 
          sx={{
            backgroundColor: "rgba(214,214,214, 0.3)",
            borderRadius: "5px",
            height: "45px",
            width: "100%",
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
              '& input': {
                padding: '10px 14px',
              },
            },
          }} />
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ fontSize: "17px" }} pb={1}>Expected CTC</Typography>
            <FormControl fullWidth>
              <Select
                id="currency"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                variant="outlined"
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  height: "45px",
                  borderRadius: "5px",
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              >
                <MenuItem value="INR">INR</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
              </Select>
            </FormControl>
          </Grid>

        <Grid item xs={12}>
        <Typography  sx={{ fontSize: "15px" }} pb={1}>Amount</Typography>
          <TextField
            fullWidth
            placeholder="Amount"
            variant="outlined"
            value={amountInLakhs}
            onKeyPress={(event) => {
              const charCode = event.charCode;
              if (!(charCode >= 48 && charCode <= 57)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => setAmountInLakhs(e.target.value)}
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              borderRadius: "5px",
              height: "45px",
              width: "100%",
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
                '& input': {
                  padding: '10px 14px',
                },
              },
            }}
          />
        </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{fontWeight: "600"}}>Accommodation</Typography>
              
            <FormControlLabel
              control={
                <Checkbox
                  checked={accommodationProvided}
                  onChange={(e) => setAccommodationProvided(e.target.checked)}
                />
              }
              label="Accommodation Provided"
            />

          </Grid>

      
          {accommodationProvided && (
          <Grid container spacing={2} ml={0} fullWdith>  
           <Grid my={2} item xs={12} md={6}>
             <FormControl fullWidth>
             <Typography  sx={{ fontSize: "15px" }} pb={1}>Furniture</Typography>
               <Select
                 fullWidth
                 variant="outlined"
                 id="accommodation-furniture"
                 label="Furniture"
                 value={accommodationFurniture}
                 onChange={(e) => setAccommodationFurniture(e.target.value)}
                 sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  height: "45px",
                  borderRadius: "5px",
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
               >
                 <MenuItem value="Furnished">Furnished</MenuItem>
                 <MenuItem value="Semi-Furnished">Semi-Furnished</MenuItem>
                 <MenuItem value="No Furniture">No Furniture</MenuItem>
               </Select>
             </FormControl>
           </Grid>
           <Grid  item xs={12} md={6}>
             <FormControl fullWidth>
             <Typography  sx={{ fontSize: "15px" }} pb={1}>Family</Typography>
               <Select
                 fullWidth
                 variant="outlined"
                 id="accommodation-family"
                 label="Family"
                 value={accommodationFamily}
                 onChange={(e) => setAccommodationFamily(e.target.value)}
                 sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  height: "45px",
                  borderRadius: "5px",
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
               >
                 <MenuItem value="Bachelor">Bachelor</MenuItem>
                 <MenuItem value="Family">Family</MenuItem>
               </Select>
             </FormControl>
           </Grid>
          </Grid> )}

          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              placeholder="Perks"

              variant="outlined"
              value={perks}
              onChange={(e) => setPerks(e.target.value)}
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                borderRadius: "5px",
                height: "45px",
                width: "100%",
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                  '& input': {
                    padding: '10px 14px',
                  },
                },
              }}
            />
          </Grid>
       </Grid>
      </CustomAccordian>

      <CustomAccordian title="Roles and Responsibilities">
        <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography sx={{ fontSize: "15px" }} pb={1}>Company</Typography>

        <TextField
          fullWidth
          variant="outlined"
          value={designation}
          placeholder="Designation"
          onChange={(e) => setDesignation(e.target.value)}
          sx={{
            backgroundColor: "rgba(214,214,214, 0.3)",
            borderRadius: "5px",
            height: "45px",
            width: "100%",
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
              '& input': {
                padding: '10px 14px',
              },
            },
          }}
        />
      </Grid>

      <Grid item xs={12} md={4}>
      <Typography sx={{ fontSize: "15px" }} pb={1}>Reporting to</Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={reportingTo}
          onChange={(e) => setReportingTo(e.target.value)}
          sx={{
            backgroundColor: "rgba(214,214,214, 0.3)",
            borderRadius: "5px",
            height: "45px",
            width: "100%",
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
              '& input': {
                padding: '10px 14px',
              },
            },
          }}
        />
      </Grid>

      <Grid item xs={12} md={4}>
      <Typography sx={{ fontSize: "15px" }} pb={1}>Reportee</Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={reportee}
          onChange={(e) => setReportee(e.target.value)}
          sx={{
            backgroundColor: "rgba(214,214,214, 0.3)",
            borderRadius: "5px",
            height: "45px",
            width: "100%",
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
              '& input': {
                padding: '10px 14px',
              },
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
        <Typography sx={{ fontSize: "15px" }} pb={1}>Role</Typography>
          <Select
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            label="Role"
            variant="outlined"
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              height: "45px",
              borderRadius: "5px",
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          >
            <MenuItem value="Individual Contributor">Individual Contributor</MenuItem>
            <MenuItem value="Team">Team</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
      <Typography sx={{ fontSize: "15px" }} pb={1}>Work Location</Typography>
          <TextField
            fullWidth
            value={workLocation}
            onChange={(e) => setWorkLocation(e.target.value)}
            variant="outlined"
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              borderRadius: "5px",
              height: "45px",
              width: "100%",
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
                '& input': {
                  padding: '10px 14px',
                },
              },
            }}
          />
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
        <Typography sx={{ fontSize: "15px" }} pb={1}>Office Type</Typography>
          <Select
            id="office-type"
            value={officeType}
            onChange={(e) => setOfficeType(e.target.value)}
            label="Office Type"
            variant="outlined"
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              height: "45px",
              borderRadius: "5px",
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          >
            <MenuItem value="Corporate Office">Corporate Office</MenuItem>
            <MenuItem value="Regional Office">Regional Office</MenuItem>
            <MenuItem value="Plant or Unit">Plant or Unit</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
      <Typography sx={{ fontSize: "15px" }} pb={1}>Brief Role</Typography>
        <TextField
          multiline
          fullWidth
          rows={3}
          value={briefRole}
          onChange={(e) => setBriefRole(e.target.value)}
          sx={{
            backgroundColor: "rgba(214,214,214, 0.3)",
            borderRadius: "5px",
            width: "100%",
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
              '& input': {
                padding: '10px 14px',
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
      <Typography sx={{ fontSize: "15px" }} pb={1}>Notice period ( in days )</Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={noticePeriod}
          onKeyPress={(event) => {
            const charCode = event.charCode;
            if (!(charCode >= 48 && charCode <= 57)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => setNoticePeriod(e.target.value)}
          sx={{
            backgroundColor: "rgba(214,214,214, 0.3)",
            borderRadius: "5px",
            height: "45px",
            width: "100%",
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
              '& input': {
                padding: '10px 14px',
              },
            },
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
      <Typography sx={{ fontSize: "15px" }} pb={1}>Days Negotiable upto</Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={daysNegotiable}
          onKeyPress={(event) => {
            const charCode = event.charCode;
            if (!(charCode >= 48 && charCode <= 57)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => setDaysNegotiable(e.target.value)}
          sx={{
            backgroundColor: "rgba(214,214,214, 0.3)",
            borderRadius: "5px",
            height: "45px",
            width: "100%",
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
              '& input': {
                padding: '10px 14px',
              },
            },
          }}
        />
      </Grid>
     
      <Grid container justifyContent="center" ml={0.5} spacing={2} my={2}>
      {kraFormData.map((kraItem, index) => (
        <Grid key={index} item xs={12} md={6}>
          <Card sx={{ mx: 'auto', p: 2, my: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "15px" }} pb={1}>KRA</Typography>
                <TextField
                  fullWidth
                  name="kra"
                  value={kraItem.kra}
                  onChange={(e) => handleKraChange(e, index)}
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    borderRadius: "5px",
                    height: "45px",
                    width: "100%",
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                      '& input': {
                        padding: '10px 14px',
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "15px" }} pb={1}>Achievement</Typography>
                <TextField
                  fullWidth
                  name="achievement"
                  value={kraItem.achievement}
                  onChange={(e) => handleKraChange(e, index)}
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    borderRadius: "5px",
                    height: "45px",
                    width: "100%",
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                      '& input': {
                        padding: '10px 14px',
                      },
                    },
                  }}
                />
              </Grid>
              {index !== 0 && (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleRemoveBoxKra(index)}
                    startIcon={<DeleteIcon />}
                  >
                    Remove KRA
                  </Button>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
        <Button
          variant="outlined"
          sx={{ color: "#272260", border: "1px solid #272260" }}
          onClick={handleAddBoxKra}
        >
          + Add Key Responsibility Area (KRA)
        </Button>
      </Grid>
    </Grid>


</Grid>
      </CustomAccordian>
        
      <CustomAccordian title="Document Upload">
      <Grid container spacing={2}>
      <Grid container spacing={2} item ml={1}>
       <Grid my={2} xs={12}>
       <Typography variant="h6" gutterBottom>
         File Upload Section:
       </Typography>
        </Grid>

      <Grid xs={12} fullWdith mb={2}>
        <FormControl fullWidth>
        <Typography sx={{ fontSize: "15px" }} pb={1}>Select File Name</Typography>
          <Select
          value={selectedFileName}
          label="Select File Name"
          onChange={(e) => setSelectedFileName(e.target.value)}
          sx={{
            backgroundColor: "rgba(214,214,214, 0.3)",
            height: "45px",
            borderRadius: "5px",
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        >
            <MenuItem value="Offer Letter with CTC Breakup">Offer Letter with CTC Breakup</MenuItem>
            <MenuItem value="Appointment Letter with CTC breakup">Appointment Letter with CTC breakup</MenuItem>
            <MenuItem value="Latest 3 Months Salary Slips">Latest 3 Months Salary Slips</MenuItem>
            <MenuItem value="Increment Letter">Increment Letter</MenuItem>
            <MenuItem value="Promotion Letter">Promotion Letter</MenuItem>
            <MenuItem value="Resignation Letter">Resignation Letter</MenuItem>
            <MenuItem value="Resignation Acceptance Letter">Resignation Acceptance Letter</MenuItem>
            <MenuItem value="Reliving Letter">Reliving Letter</MenuItem>
            <MenuItem value="Experience Letter">Experience Letter</MenuItem>
            <MenuItem value="Achievements Rewards Certification Document">Achievements / Rewards Certification Document</MenuItem>
            <MenuItem value="Others">Others </MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Drag & drop files here, or click to select files</p>
      </div>

      {selectedFile && (
        <Typography variant="subtitle1" gutterBottom>
          Selected File: {selectedFile.name}
        </Typography>
      )}

      <Grid mt={1} mb={2} xs={8} mx="auto">
        <Button fullWidth variant="contained" sx={{backgroundColor: "#272260", color: "white"}}  onClick={handleUpload}>
        + Add File
        </Button>
      </Grid>

      {uploadedFiles.length > 0 && (
        <div>
          <Typography variant="h5" gutterBottom>
            Uploaded Files
          </Typography>
          {uploadedFiles.map((file, index) => (
            <div key={index} style={fileContainerStyles}>
              <Typography variant="body1">{file.fileName}</Typography>
              <Typography variant="body2">{file.file.name}</Typography>
              <Button variant="outlined" color="error" onClick={() => handleRemove(index)}>
                Remove
              </Button>
            </div>
          ))}
        </div>
      )}
</Grid>
</Grid>
      </CustomAccordian>


        <Grid item container >

          <Grid mx="auto" my={2} px={1} item xs={12}>
            <Button
                variant="contained"
                fullWidth
                style={{
                  color: "white",
                  backgroundColor: "#272260"
                }}
                onClick={handleSaveClick}
            >
                SAVE
            </Button>
          </Grid>         
        </Grid>


        </Grid>
        </Container>

    </Container>
    </>
  );
}


import React, { useState } from "react";
import { Button, Container, Grid, TextField, Checkbox, Typography, Paper, FormControl, InputLabel, MenuItem, Select, makeStyles } from "@mui/material";
import NavBar from "../../../components/NavBar";
import instance from "../../../globals/axios.js";
import { SERVER_BASE_ADDRESS } from "../../../globals/const";
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Sidebar from "../../../components/Sidebar.js";
import ProfileHeader from "../../../components/ProfileHeader.js";


const DropzoneContainer = styled('div')({
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: '20px',
});


export default function AdditionalInfo() {

  const [formData, setFormData] = useState({
    disability: "",
    visa_type: "",
    visa_country: ""
    // visa_valid_till: "",
  });

  const [ has_visa_card, setHas_visa_card ] = useState(false)
  const [ is_differently_abled, setIs_differently_abled] = useState(false)

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const [visaFile, setVisaFile] = useState(null);
  const [diffAbledFile, setDiffAbledFile] = useState(null);

  const onDropVisa = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setVisaFile(acceptedFiles[0]);
    }
  };

  const onDropDiffAbled = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setDiffAbledFile(acceptedFiles[0]);
    }
  };

  const { getRootProps: getVisaRootProps, getInputProps: getVisaInputProps } = useDropzone({
    accept: '.pdf, .jpeg, .jpg, .png', 
    onDrop: onDropVisa,
  });

  const { getRootProps: getDiffAbledRootProps, getInputProps: getDiffAbledInputProps } = useDropzone({
    accept: '.pdf, .jpeg, .jpg, .png',  
    onDrop: onDropDiffAbled,
  });

  const handleSaveClick = async () => {
   
    const sendData = new FormData();
    sendData.append('disability',formData.disability)
    sendData.append('visa_type',formData.visa_type)
    sendData.append('visa_country',formData.visa_country)

    console.log(sendData)
    if(visaFile)
      sendData.append('visa_file', visaFile);

    if(diffAbledFile)
      sendData.append('diff_abled_file', diffAbledFile);
  
    console.log([...sendData])

    try {
      const response = await instance.post("additional-info/", sendData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert("Additonal data added Successfully");
      setTimeout(() => {
        window.location = "/edit-language-proficiency";
      }, 200);

    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <>
      <Sidebar  />
      <Container sx={{ px:2 }} maxWidth="xs" style={{ marginTop: "30px" }}>
      <ProfileHeader showUploadIcon={false} />
      <Grid xs={10} mx="auto" my={1}>
          <Typography variant="h6" sx={{fontWeight: "600"}}
          >
            Additional Information
          </Typography>
          
        </Grid>
        <div>
          {/* Differently Abled section */}

          <Link 
          to="#"
          onClick={() => {
            setIs_differently_abled(!is_differently_abled)
          }}
          style={{ textDecoration: 'none' }}
          >

            <div
            
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <label
                  htmlFor=""
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    letterSpacing: "1.5px",
                    color: "rgb(5, 32, 71)",
                  }}
                >
                  Diff. Abled
                </label>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Checkbox
                  checked={is_differently_abled}
                  onChange={setIs_differently_abled}
                  name="is_differently_abled"
                  color="primary"
                />
              </div>
            </div>

          </Link>

          {is_differently_abled && (
            <Grid my={1} container spacing={2}>
              {/*
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Type of Disability</InputLabel>
                  <Select
                    name="type_of_disability"
                    value={formData.type_of_disability}
                    onChange={handleChange}
                  >
                    <MenuItem value="Blindness">Blindness</MenuItem>
                    <MenuItem value="Hearing Impairment">Hearing Impairment</MenuItem>
                    <MenuItem value="Mental Illness">Mental Illness</MenuItem>
                    <MenuItem value="Handicap">Handicap</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
               */}
              <Grid item xs={12}>
                <TextField
                  label="Mention Disability"
                  variant="outlined"
                  name="disability"
                  value={formData.disability}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid p={4}>
                <Typography variant="h6" sx={{ textAign:"center", fontAlign: "center"}} gutterBottom>
                    Upload Differently Abled Proof File
                  </Typography>
                  <DropzoneContainer {...getDiffAbledRootProps()}>
                    <input {...getDiffAbledInputProps()} />
                    <Typography variant="body1">
                      Drag and drop a PDF file here, or click to select one.
                    </Typography>
                  </DropzoneContainer>
                  {diffAbledFile && (
                    <Typography variant="body1">Differently Abled File: {diffAbledFile.name}</Typography>
                  )}
              </Grid>
            </Grid>
          )}
        </div>

        <div>
          {/* Visa section */}

          <Link 
          to="#"
          onClick={() => {
            setHas_visa_card(!has_visa_card)
          }}
          style={{ textDecoration: 'none' }}
          >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <label
                htmlFor=""
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  letterSpacing: "1.5px",
                  color: "rgb(5, 32, 71)",
                }}
              >
                Visa
              </label>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Checkbox
              checked={has_visa_card}
              onChange={setHas_visa_card}
                name="has_visa_card"
                color="primary"
              />
            </div>
          </div>
          </Link>


          {has_visa_card && (
            <Grid my={1} container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Type of Visa</InputLabel>
                  <Select
                    name="visa_type"
                    value={formData.visa_type}
                    onChange={handleChange}
                  >
                    <MenuItem value="Work">Work</MenuItem>
                    <MenuItem value="Student">Student</MenuItem>
                    <MenuItem value="Permanent">Permanent</MenuItem>
                    <MenuItem value="Tourist">Tourist</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Country"
                  variant="outlined"
                  name="visa_country"
                  value={formData.visa_country}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              

              <Grid p={4}>
                <Typography variant="h6" sx={{ textAign:"center", fontAlign: "center"}} gutterBottom>
                  Upload Visa File
                </Typography>
                <DropzoneContainer {...getVisaRootProps()}>
                  <input {...getVisaInputProps()} />
                  <Typography variant="body1">
                    Drag and drop a PDF file here, or click to select one.
                  </Typography>
                </DropzoneContainer>
                {visaFile && (
                  <Typography variant="body1">Visa File: {visaFile.name}</Typography>
                )}
              </Grid>


            </Grid>
          )}
        </div>

        

        <Button
          variant="contained"
          fullWidth
          sx={{
            color: "white",
            backgroundColor: "#272260",
          }}
          mt={2}
          onClick={handleSaveClick}
        >
          SAVE
        </Button>
      </Container>
    </>
  );
}
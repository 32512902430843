import React from "react";
import { Button, TextField, Grid, Typography, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useState } from "react";
import { HOME_PAGE } from "../globals/const";
import { SERVER_BASE_ADDRESS } from "../globals/const";
import { saveAuth, saveRole } from "../globals/utils";
import instance from "../globals/axios.js";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { isLoggedIn } from "../globals/utils";
import CustomButton from "../components/Button/CustomButtom.js";

import { MuiOtpInput } from "mui-one-time-password-input";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import logo from "../../src/assets/iveri.png";
import AppleIcon from "../assets/appleIcon.png";
import GoogleIcon from "../assets/googleIcon.png";

export default function Login() {
  const [creds, setCreds] = useState({});

  async function login(event) {
    event.preventDefault();
  }
  function setCredField(key, value) {
    let _creds = creds;
    creds[key] = value;
    setCreds(_creds);
  }

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const mobileBody = {
    mobile: phoneNumber.slice(2),
    send: 1,
  };

  const otpBody = {
    mobile: phoneNumber,
    verify: 1,
    otp: otp,
  };

  const loginWithOtpBody = {
    ft_token: "3kj4rn34k",
    mobile_number: phoneNumber.slice(2),
  };

  const handlePhoneNumberChange = (formattedValue) => {
    setPhoneNumber(formattedValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(creds);

    instance
      .post(SERVER_BASE_ADDRESS + "user/login/", creds)
      .then((res) => {
        console.log("Response", res);
        console.log("Res data", res.data);
        window.localStorage.setItem("access_token", res.data.access);
        window.localStorage.setItem("refresh_token", res.data.refresh);
        window.localStorage.setItem("first_name", res.data.first_name);
        window.localStorage.setItem("last_name", res.data.last_name);
        window.localStorage.setItem("iveri_id", res.data.iveri_id);
        window.localStorage.setItem("role", res.data.role);
        window.localStorage.setItem("company_id", res.data.company_id);
        window.localStorage.setItem("email", res.data.email);
        window.localStorage.setItem("email_validated", res.data.email_validated);
        window.localStorage.setItem("phone_number", res.data.phone_number);
        window.localStorage.setItem("phone_validated", res.data.phone_validated);

        saveAuth(res.data);

        alert("Login Successfully");

        console.log("res", res.data);
        if (res.data.role === "User") {
          // const emailValidated = localStorage.getItem('email_validated') === 'true';
          // const phoneValidated = localStorage.getItem('phone_validated') === 'true';
          // if (emailValidated && phoneValidated) {
          //   window.open("/profile-page", "_self");
          // } else {
          //   window.open("/verify", "_self");
          // }
          window.open("/apply-jobs", "_self");
        }
        if (res.data.role === "Agency") {
          window.open("/agency-menu", "_self");
        }
        if (res.data.role === "Employer") {
          window.open("/emp-menu", "_self");
        }
        if (res.data.role === "Hiring Manager") {
          window.open("/hiring-manager", "_self");
        }
        if (res.data.role === "HR") {
          window.open("/human-resources", "_self");
        }
        if (res.data.role === "Recruiter") {
          window.open("/recruiter", "_self");
        }
      })
      .catch((e) => {
        // this.setState ({errorText: 'Authentication Failure'});
        alert("Authentication Failed");
      });
  };

  return (
    <Container component="main" fullWidth>
      <Grid fullWidth mt="8%" container justifyContent="center">
        <Grid item my="auto" xs={12} md={5}>
          <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
            <img mx="auto" alt="iVeri logo" src={logo} style={{ width: "150px", height: "80px" }} />
          </div>
          <form noValidate onSubmit={login}>
            <Grid item xs={12} px={2}>
              <Typography variant="h4" sx={{ fontWeight: 600, pb: 1 }}>
                Login
              </Typography>
              <Typography sx={{ color: "#999999", fontSize: "13px", pb: 1 }}>
                Enter the correct details to login your account!
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email ID"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(event) => setCredField("email", event.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12} px={2}>
            <TextField 
              variant='outlined' margin='normal' required fullWidth 
              name='password' label={"Password"} type='password' id='password' autoComplete='current-password'
                onChange={(event) => setCredField("password", event.target.value)}
            />
            </Grid> */}
            <Grid item xs={12} px={2}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={(event) => setCredField("password", event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleTogglePassword}
                        aria-label="toggle password visibility">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid xs={12} px={2} mt={3} mx="auto">
              {/* <Button
                type="submit"
                fullWidth
                sx={{ width: "100%", backgroundColor: "#272260" }}
                variant="contained"
                onClick={handleSubmit}>
                Login
              </Button> */}
              <CustomButton onClick={handleSubmit}>{"Login"}</CustomButton>
            </Grid>
          </form>
          <Grid mx="auto" container justifyContent="center" style={{ marginTop: "20px" }}>
            <Link to="/forgot-password" style={{ textDecoration: "none" }}>
              <Box fullWidth>Forgot Password</Box>
            </Link>

            <Link to="/change-phone-number" style={{ textDecoration: "none" }}>
              <Box ml={4} fullWidth>
                Change Phone Number
              </Box>
            </Link>
          </Grid>
          <Divider style={{ margin: "20px 0" }}>or</Divider>

          <Grid container style={{ marginTop: "20px", display: "flex", flexWrap: "nowrap" }}>
            <Grid item xs={6} style={{ padding: "0 8px" }}>
              <Link to="#" style={{ textDecoration: "none" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={1.5}
                  sx={{ backgroundColor: "#EFEFF0", borderRadius: "4px" }}>
                  <img src={GoogleIcon} alt="Google Icon" />
                  <Typography color="black" style={{ marginLeft: "8px" }}>
                    Google
                  </Typography>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6} style={{ padding: "0 8px" }}>
              <Link to="#" style={{ textDecoration: "none" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={1.5}
                  sx={{ backgroundColor: "#EFEFF0", borderRadius: "4px" }}>
                  <img src={AppleIcon} alt="Apple Icon" />
                  <Typography color="black" style={{ marginLeft: "8px" }}>
                    Apple
                  </Typography>
                </Box>
              </Link>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" style={{ marginTop: "40px" }}>
            <Link to="/sign-up" style={{ textDecoration: "none" }}>
              <Box textAlign="center">
                <Typography variant="body2" color="textSecondary" component="span" pr={0.5}>
                  Don't have an Account
                </Typography>
                <Typography variant="body2" color="#272260" component="span">
                  Sign Up?
                </Typography>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

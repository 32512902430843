import React, { useEffect, useState, } from "react";
import {
  Button,
  Container,
  Typography,
  Grid,
  TextField,
  Select,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  MenuItem,
  Autocomplete,
  Chip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./DatePicker.css";

import { SERVER_BASE_ADDRESS } from "../../../globals/const";
import instance from "../../../globals/axios.js";
import dayjs from "dayjs";
import { FaLinkedin, FaBehance, FaGithub, FaFacebook, FaUser } from "react-icons/fa";
import ProfileHeader from "../../../components/ProfileHeader.js";
import Sidebar from "../../../components/Sidebar.js";


export default function PersonalDetailsinfo() {
  const [isSaved, setIsSaved] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    adhaar_number: "",
    pan_number: "",
    birth_date: null,
    gender: "",
    relationship_status: "",
    family: [{ relation: "", name: "", age: "", occupation: "" }],
    hobbies: [],
    linkedin: "",
    github: "",
    facebook: "",
    behance: "",
    other: "",
    videoFile: null,
    aadhar_file: null,
    pan_file: null,
    profilePic: null,
  });
  const [newHobby, setNewHobby] = useState("");
  const hobbiesArray = [
    "Reading",
    "Painting",
    "Cooking",
    "Photography",
    "Hiking",
    "Gardening",
    "Playing Guitar",
    "Swimming",
    "Dancing",
    "Traveling",
    "Yoga",
    "Fishing",
    "Singing",
    "Chess",
    "Cycling",
    "Writing",
    "Knitting",
    "Watching Movies",
    "Playing Tennis",
    "Collecting Stamps",
    "Mountain Climbing",
    "Skydiving",
    "Scuba Diving",
    "Bird Watching",
    "Pottery",
    "Woodworking",
    "Meditation",
    "Volunteering",
    "Archery",
    "Cooking",
    "Skiing",
    "Baking",
    "Camping",
    "Running",
    "Drawing",
    "Puzzle Solving",
    "Sculpting",
    "Golfing",
    "Surfing",
    "Sewing",
    "Horseback Riding",
    "Ice Skating",
    "Rowing",
    "Bowling",
    "Billiards",
    "Model Building",
    "Calligraphy",
    "Astronomy",
    "Collecting Coins",
  ];

  const updateFormData = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBirthDateChange = (date) => {
    if (date) {
      const selectedDate = dayjs(date).toDate(); // Convert to Date object
      setFormData((prevData) => ({
        ...prevData,
        birth_date: selectedDate ? dayjs(selectedDate).format("YYYY-MM-DD") : null,
      }));
    }
  };

  const clearBirthDate = () => {
    setFormData((prevData) => ({
      ...prevData,
      birth_date: null,
    }));
  };


  const handleRelationshipChange = (event) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      relationship_status: value,
    }));
  };

  const handleFamilyChange = (index, field, value) => {
    const updatedFamily = [...formData.family];
    updatedFamily[index][field] = value;
    setFormData((prevData) => ({
      ...prevData,
      family: updatedFamily,
    }));
  };

  const handleAddFamilyMember = () => {
    setFormData((prevData) => ({
      ...prevData,
      family: [...prevData.family, { relation: "", name: "", age: "", occupation: "" }],
    }));
  };

  const handleRemoveFamilyMember = (index) => {
    const updatedFamily = [...formData.family];
    updatedFamily.splice(index, 1);
    setFormData((prevData) => ({
      ...prevData,
      family: updatedFamily,
    }));
  };

  const handleHobbiesChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      hobbies: [...prevData.hobbies, ...newValue],
    }));
  };


  const handleAddHobby = () => {
    if (newHobby && !formData.hobbies.includes(newHobby)) {
      setFormData((prevData) => ({
        ...prevData,
        hobbies: [...prevData.hobbies, newHobby],
      }));
      setNewHobby("");
    }
  };

  const handleRemoveHobby = (hobbyToRemove) => {
    setFormData((prevData) => ({
      ...prevData,
      hobbies: prevData.hobbies.filter((hobby) => hobby !== hobbyToRemove),
    }));
  };

  useEffect(() => {
    instance
      .get("basic-info/")
      .then((res) => {
        const basicInfoData = res.data;
        updateFormData(basicInfoData);
      })
      .catch((error) => {
        console.error("Error fetching basic info:", error);
      });
  }, []);

  const [aadharFile, setAadharFile] = useState(null);
  const [panFile, setPanFile] = useState(null);



  const handleSubmit = (event) => {
    event.preventDefault();
    const formDataToSend = new FormData();

    formDataToSend.append("first_name", formData.first_name);
    formDataToSend.append("middle_name", formData.middle_name);
    formDataToSend.append("last_name", formData.last_name);
    formDataToSend.append("mobile_number", formData.mobile_number);
    formDataToSend.append("email", formData.email);

    console.log(formData.adhaar_number);
    console.log(formData.pan_number);

    if (
      formData.adhaar_number !== "" &&
      formData.adhaar_number !== null &&
      formData.adhaar_number !== "null"
    ) {
      if (!/^\d{12}$/.test(formData.adhaar_number)) {
        alert("Aadhar number must be 12 digits");
        return;
      }
    }

    if (
      formData.pan_number !== "" &&
      formData.pan_number !== null &&
      formData.pan_number !== "null"
    ) {
      if (!/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(formData.pan_number)) {
        alert("Invalid PAN card number");
        return;
      }
    }

    formDataToSend.append("adhaar_number", formData.adhaar_number);
    formDataToSend.append("pan_number", formData.pan_number);

    if (formData.gender !== null && formData.gender !== "") {
      formDataToSend.append("gender", formData.gender);
    }

    if (formData.relationship_status !== null && formData.relationship_status !== "") {
      formDataToSend.append("relationship_status", formData.relationship_status);
    }

    formDataToSend.append("family", formData.family);

    // const isValidURL = (url) => /^(http|https):\/\/[^\s$.?#].[^\s]*$/i.test(url);

    const isValidURL = (url) => {
      const pattern =
        /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
      return pattern.test(url);
    };

    if (formData.linkedin !== null && formData.linkedin !== "" && formData.linkedin !== "null") {
      const linkedinIsValid = isValidURL(formData.linkedin);
      if (linkedinIsValid) {
        formDataToSend.append("linkedin", formData.linkedin);
      } else {
        alert("LinkedIn link is invalid.");
        return;
      }
    }

    if (formData.github !== null && formData.github !== "" && formData.github !== "null") {
      console.log("git link", formData.facebook);

      const githubIsValid = isValidURL(formData.github);
      if (githubIsValid) {
        formDataToSend.append("github", formData.github);
      } else {
        alert("GitHub link is invalid.");
        return;
      }
    }

    // Validate and append Facebook link
    if (formData.facebook !== null && formData.facebook !== "" && formData.facebook !== "null") {
      const facebookIsValid = isValidURL(formData.facebook);
      console.log("link", formData.facebook);
      if (facebookIsValid) {
        formDataToSend.append("facebook", formData.facebook);
      } else {
        alert("Facebook link is invalid.");
        return;
      }
    }

    // Validate and append Behance link
    if (formData.behance !== null && formData.behance !== "" && formData.behance !== "null") {
      const behanceIsValid = isValidURL(formData.behance);
      if (behanceIsValid) {
        formDataToSend.append("behance", formData.behance);
      } else {
        alert("Behance link is invalid.");
        return;
      }
    }

    // Validate and append Other link
    if (formData.other !== null && formData.other !== "" && formData.other !== "null") {
      const otherIsValid = isValidURL(formData.other);
      if (otherIsValid) {
        formDataToSend.append("other", formData.other);
      } else {
        alert("Other link is invalid.");
        return;
      }
    }

    formDataToSend.append("videoFile", formData.videoFile);

    if (formData.birth_date) {
      formDataToSend.append("birth_date", formData.birth_date);
    }

    // Include hobbies if provided
    if (formData.hobbies.length > 0) {
      formDataToSend.append("hobbies", formData.hobbies);
    }

    if (aadharFile) {
      formDataToSend.append("aadhar_file", aadharFile);
    }
    if (panFile) {
      formDataToSend.append("pan_file", panFile);
    }
    console.log(formDataToSend);

    instance
      .post(SERVER_BASE_ADDRESS + "basic-info/", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
        setIsSaved(true);
        alert("Data Saved Successfully");
        setTimeout(() => {
          window.location = "/edit-address";
        }, 200);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    if (!(charCode >= 65 && charCode <= 90) && !(charCode >= 97 && charCode <= 122)) {
      event.preventDefault();
    }
  };

  const handleNextClick = () => {
    setIsSaved(true);
  };

  const handleBackClick = () => {
    setIsSaved(false);
  };


  return (
    <>
      <Sidebar />
      <Container maxWidth="xs" style={{ marginTop: "30px" }}>
        <Container>
          <ProfileHeader showUploadIcon={false} />
          <Grid container spacing={2} mt={1}>
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: "600" }} mb={1.5}>
                Personal Information
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "15px" }} pb={1}>
              Name*
            </Typography>
            <TextField
              value={
                formData.first_name +
                (formData.middle_name ? " " + formData.middle_name : "") +
                (formData.last_name ? " " + formData.last_name : "")
              }
              onChange={handleInputChange}
              placeholder="Name"
              required
              fullWidth
              size="small"
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                borderRadius: "5px",
                height: "45px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                  "& input": {
                    padding: "10px 14px",
                  },
                },
              }}
            />
          </Grid>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <Typography>Gender*</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "-12px" }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}>
                    <FormControlLabel value="M" control={<Radio />} label="Male" />
                    <FormControlLabel value="F" control={<Radio />} label="Female" />
                    <FormControlLabel value="O" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography>Date of Birth</Typography>
              </Grid>
              {formData.birth_date ? (
                <Grid item mt={-1} xs={12}>
                  <Box display="flex" fullWidth>
                    <TextField
                      label="Birth Date"
                      sx={{ backgroundColor: "rgba(214,214,214, 0.3)" }}
                      value={formData.birth_date}
                      fullWidth
                      disabled
                    />
                    <IconButton onClick={clearBirthDate} size="small" color="primary">
                      <EditIcon />
                    </IconButton>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Box fullWidth display="flex" justifyContent="center">
                    <DatePicker
                      className="custom-date-picker"
                      value={formData.birth_date}
                      onChange={handleBirthDateChange}
                      format="y-MM-dd"
                      clearIcon={null}
                      dayPlaceholder="dd"
                      monthPlaceholder="mm"
                      yearPlaceholder="yyyy"
                      calendarIcon={<span>📅</span>}
                      style={{
                        width: "100%",
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        height: "45px",
                      }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>



            <Grid container spacing={2} mt={0.25}>
              <Grid item xs={12}>
                <Typography style={{ marginTop: "6px" }}>Relationship Status</Typography>
              </Grid>
              <Grid item mt={-1} xs={12}>
                <FormControl fullWidth>
                  <Select
                    id="relationship-input-id"
                    labelId="relationship-input"
                    value={formData.relationship_status}
                    onChange={handleRelationshipChange}
                    variant="standard"
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      "&::before, &::after": { border: "none" },
                      height: "45px",
                      borderRadius: "5px",
                    }}
                    fullWidth>
                    <MenuItem value="S">Single</MenuItem>
                    <MenuItem value="M">Married</MenuItem>
                    <MenuItem value="D">Divorced</MenuItem>
                    <MenuItem value="W">Widowed</MenuItem>
                    <MenuItem value="H">Home-Maker</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={0.25}>
              <Grid item xs={12}>
                <Typography style={{ marginTop: "6px" }}>Hobbies</Typography>
              </Grid>
              <Grid item mt={-1} xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={hobbiesArray}
                      getOptionLabel={(option) => option}
                      value={newHobby}
                      onChange={(event, newValue) => setNewHobby(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          fullWidth
                          sx={{
                            backgroundColor: "rgba(214,214,214, 0.3)",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                            },
                            borderRadius: "5px",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddHobby}
                      fullWidth
                      sx={{
                        backgroundColor: "white",
                        minWidth: "30px",
                        minHeight: "35px",
                        color: "#666671",
                        border: "1px solid #666671",
                        textTransform: "none",
                        boxShadow: "none",
                        fontSize: "15px",
                        padding: "10px 0px",
                        "&:hover": {
                          backgroundColor: "white",
                          border: "1px solid #666671",
                          boxShadow: "none",
                        },
                        "&:active": {
                          backgroundColor: "white",
                          border: "1px solid #666671",
                          boxShadow: "none",
                        },
                      }}>
                      + Add Hobby
                    </Button>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    {formData.hobbies.map((hobby) => (
                      <Chip
                        key={hobby}
                        label={hobby}
                        onDelete={() => handleRemoveHobby(hobby)}
                        style={{ margin: "0 4px 4px 0" }}
                      />
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={0.25}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: "600" }}>
                  Add Family Details
                </Typography>
                {formData.family.map((member, index) => (
                  <Grid container spacing={2} key={index} mt={0.25}>
                    <Grid item xs={12}>
                      <Typography pb={1.5} mb={-1}>
                       Family Relationship
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          labelId={`relation-label-${index}`}
                          label="relation"
                          value={member.relation}
                          onChange={(event) =>
                            handleFamilyChange(index, "relation", event.target.value)
                          }
                          sx={{
                            backgroundColor: "rgba(214,214,214, 0.3)",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            height: "45px",
                            borderRadius: "5px",
                          }}>
                          <MenuItem value="Father">Father</MenuItem>
                          <MenuItem value="Mother">Mother</MenuItem>
                          <MenuItem value="Daughter">Daughter</MenuItem>
                          <MenuItem value="Son">Son</MenuItem>
                          <MenuItem value="Brother">brother</MenuItem>
                          <MenuItem value="Sister">Sister</MenuItem>
                          <MenuItem value="Spouse">Spouse</MenuItem>
                          <MenuItem value="SonInLaw">Son in Law</MenuItem>
                          <MenuItem value="DaughterInLaw">Daughter in Law</MenuItem>
                          <MenuItem value="FatherInLaw">Father in Law</MenuItem>
                          <MenuItem value="MotherInLaw">Mother in Law</MenuItem>
                          <MenuItem value="BrotherInLaw">Brother in Law</MenuItem>
                          <MenuItem value="SisterInLaw">Sister in Law</MenuItem>
                          <MenuItem value="Other">other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography pb={1.5} mb={-1}>
                        Name
                      </Typography>
                      <TextField
                        value={member.name}
                        onChange={(event) => handleFamilyChange(index, "name", event.target.value)}
                        fullWidth
                        placeholder="Name"
                        sx={{
                          backgroundColor: "rgba(214,214,214, 0.3)",
                          borderRadius: "5px",
                          height: "45px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                            },
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                            "& input": {
                              padding: "10px 14px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography pb={1.5} mb={-1}>
                        Age
                      </Typography>
                      <TextField
                        placeholder="Age"
                        value={member.age}
                        onChange={(event) => handleFamilyChange(index, "age", event.target.value)}
                        fullWidth
                        onKeyPress={(event) => {
                          const charCode = event.charCode;
                          if (!(charCode >= 48 && charCode <= 57)) {
                            event.preventDefault();
                          }
                        }}
                        sx={{
                          backgroundColor: "rgba(214,214,214, 0.3)",
                          borderRadius: "5px",
                          height: "45px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                            },
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                            "& input": {
                              padding: "10px 14px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl sx={{ width: "100%", display: "flex" }} fullWidth>
                        <Typography pb={1.5} mb={-1}>
                          Occupation
                        </Typography>
                        <Select
                          labelId={`occupation-label-${index}`}
                          value={member.occupation}
                          label="Occupation"
                          onChange={(event) =>
                            handleFamilyChange(index, "occupation", event.target.value)
                          }
                          sx={{
                            backgroundColor: "rgba(214,214,214, 0.3)",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            height: "45px",
                            borderRadius: "5px",
                          }}>
                          <MenuItem value="Student">Student</MenuItem>
                          <MenuItem value="Employed">Employed</MenuItem>
                          <MenuItem value="Unemployed">Unemployed</MenuItem>
                          <MenuItem value="Self-Employed">Self-Employed</MenuItem>
                          <MenuItem value="Retired">Retired</MenuItem>
                          <MenuItem value="Home Maker">Home Maker</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      {index > 0 ? (
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item>
                              <Button
                                variant="outlined"
                                sx={{
                                  backgroundColor: "#272260",
                                  color: "white",
                                  height: "25px",
                                  fontSize: "12px",
                                  textTransform: "none",
                                  padding: "3px 12px",
                                }}>
                                Edit
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="outlined"
                                onClick={() => handleRemoveFamilyMember(index)}
                                sx={{
                                  border: "1px solid #272260",
                                  color: "#272260",
                                  height: "25px",
                                  fontSize: "12px",
                                  textTransform: "none",
                                  padding: "3px 12px",
                                }}>
                                Delete
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={12} mt={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddFamilyMember}
                            fullWidth
                            sx={{
                              backgroundColor: "white",
                              minWidth: "30px",
                              minHeight: "35px",
                              color: "#666671",
                              border: "1px solid #666671",
                              textTransform: "none",
                              boxShadow: "none",
                              fontSize: "15px",
                              padding: "10px 0px",
                              "&:hover": {
                                backgroundColor: "white",
                                border: "1px solid #666671",
                                boxShadow: "none",
                              },
                              "&:active": {
                                backgroundColor: "white",
                                border: "1px solid #666671",
                                boxShadow: "none",
                              },
                            }}>
                            + Add New
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid container alignItems="center" spacing={2} mt={0.25}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: "600" }}>
                  Social Media Links
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <FaLinkedin size={30} color="primary" />
              </Grid>
              <Grid item ml={2} xs={10}>
                <TextField
                  label="Linkedin"
                  variant="standard"
                  name="linkedin"
                  value={formData.linkedin === "null" ? null : formData.linkedin}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={1}>
                <FaGithub size={30} color="primary" />
              </Grid>
              <Grid item ml={2} xs={10}>
                <TextField
                  label="Github"
                  variant="standard"
                  name="github"
                  value={formData.github === "null" ? null : formData.github}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={1}>
                <FaBehance size={30} color="primary" />
              </Grid>
              <Grid item ml={2} xs={10}>
                <TextField
                  label="Behance"
                  variant="standard"
                  name="behance"
                  value={formData.behance === "null" ? null : formData.behance}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={1}>
                <FaFacebook size={30} color="primary" />
              </Grid>
              <Grid item ml={2} xs={10}>
                <TextField
                  label="Facebook"
                  variant="standard"
                  name="facebook"
                  value={formData.facebook === "null" ? null : formData.facebook}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={1}>
                <FaUser size={30} color="primary" />
              </Grid>
              <Grid item ml={2} xs={10}>
                <TextField
                  label="Any Other"
                  variant="standard"
                  name="other"
                  value={formData.other === "null" ? null : formData.other}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddFamilyMember}
                  fullWidth
                  sx={{
                    backgroundColor: "white",
                    minWidth: "30px",
                    minHeight: "35px",
                    color: "#666671",
                    border: "1px solid #666671",
                    textTransform: "none",
                    boxShadow: "none",
                    fontSize: "15px",
                    padding: "10px 0px",
                    "&:hover": {
                      backgroundColor: "white",
                      border: "1px solid #666671",
                      boxShadow: "none",
                    },
                    "&:active": {
                      backgroundColor: "white",
                      border: "1px solid #666671",
                      boxShadow: "none",
                    },
                  }}>
                  + Add New
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={8} mx="auto" my={2}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                sx={{ backgroundColor: "#272260" }}
                nextRoute={!isSaved ? undefined : "/edit-address"}>
                Save
              </Button>
            </Grid>
            <Grid item xs={8} mx="auto" mb={4}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleBackClick}
                sx={{ border: "1px solid #272260", color: "#272260" }}>
                Back
              </Button>
            </Grid>
          </form>
        </Container>
      </Container>
    </>
  );
}

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Timelinelabel = ({ label, value }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
    <Box
      sx={{
        width: '8px !important',
        height: '8px !important',
        borderRadius: '50% !important',
        backgroundColor: '#262262 !important',
        marginRight: '8px !important',
      }}
    />
    <Typography variant="body1" sx={{ color: "#262262", fontWeight: '600 !important' }}>
      {label}: <Box component="span" sx={{ color: 'black !important', fontWeight: '600 !important' }}>{value || 'N.A.'}</Box>
    </Typography>
  </Box>
);

export default Timelinelabel;

import React, { useState } from 'react';
import { Typography, Button, CircularProgress, Box, Paper, Grid, Card, Container, Chip } from '@mui/material';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
  } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import NavBar from "../components/NavBar";
import instance from '../globals/axios';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import LanguageIcon from '@mui/icons-material/Language';
// import SkillIcon from '@mui/icons-material/Skills';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import TimelineIcon from '@mui/icons-material/Timeline';
import ExtensionIcon from '@mui/icons-material/Extension';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Sidebar from '../components/Sidebar';


const ResumeExtractor = () => {
    const [loading, setLoading] = useState(false);
    const [extractedData, setExtractedData] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [skills, setSkills] = useState([]);
    const [collegeName, setCollegeName] = useState('');
    const [degree, setDegree] = useState('');
    const [designation, setDesignation] = useState('');
    const [experience, setExperience] = useState([]);
    const [companyNames, setCompanyNames] = useState([]);
    const [noOfPages, setNoOfPages] = useState(0);
    const [totalExperience, setTotalExperience] = useState(0);
  
    const onDrop = (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    };
  
    const handleExtractData = async () => {
      if (selectedFile) {
        const formData = new FormData();
        formData.append('resume', selectedFile);
  
        try {
          setLoading(true);
  
          const response = await instance.post('extract-resume-data/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          
          setExtractedData(response.data.data);

          setName(response.data.data.name || '');
          setEmail(response.data.data.email || '');
          setMobileNumber(response.data.data.mobile_number || '');
          setSkills(response.data.data.skills || []);
          setCollegeName(response.data.data.college_name || '');
          setDegree(response.data.data.degree || '');
          setDesignation(response.data.data.designation || '');
          setExperience(response.data.data.experience || []);
          setCompanyNames(response.data.data.company_names || []);
          setNoOfPages(response.data.data.no_of_pages || 0);
          setTotalExperience(response.data.data.total_experience || 0);

  
        } catch (error) {
          console.error('Error extracting data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        console.error('Please select a file before extracting data.');
      }
    };
  
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: '.pdf',
    });

    const handleAddToProfile = async (selectedSkill) => {
      try {

        const response = await instance.post("edit-skills/", skills);

            alert('Skills Added to your profile successfully!');
            // setTimeout(() => {
            //   window.location = "/edit-document-locker";
            // }, 200);
        
        } catch (error) {
          console.error('Error:', error);
        }
    };
  

    return (
      <>
      <Sidebar />
      <Container maxWidth="xs" sx={{ p:2, marginTop: "3rem" }} >
      <Box p={1} textAlign="center">
        <Typography variant="h4" mb={3}>
          Resume Extractor
        </Typography>
        <div {...getRootProps()} style={{ outline: 'none' }}>
          <input {...getInputProps()} />
          <Typography variant="body1" mb={2}>
            Drag and drop your resume (PDF) here, or click to select a file.
          </Typography>
          <Button variant="contained" color="primary">
            Select Resume
          </Button>
        </div>

        {selectedFile && (
          <Box mt={3}>
            <Typography variant="body1">Selected File: {selectedFile.name}</Typography>
          </Box>
        )}

    

        {loading ? 
        ( <CircularProgress style={{ marginTop: '20px' }} />): 
        (
          <Button
          variant="contained"
          color="primary"
          disabled={!selectedFile || loading}
          onClick={handleExtractData}
          style={{ marginTop: '20px' }}
        >
          Extract Data
        </Button>
        )
        }
        
        {extractedData && (
          <Box p={3}>
          <Typography variant="h5" mb={2}>
              Name:  {name}
          </Typography>
    
          <List>
              <Grid container spacing={2}>
                  <Grid item xs={12} >
                  <ListItem>
                      <ListItemIcon>
                      <EmailIcon />
                      </ListItemIcon>
                      <ListItemText primary={`Email: ${email}`} />
                  </ListItem>
                  </Grid>

                  <Grid item xs={12} >
                  <ListItem>
                      <ListItemIcon>
                      <PhoneIcon />
                      </ListItemIcon>
                      <ListItemText primary={`Phone Number: +${mobileNumber}`} />
                  </ListItem>
                  </Grid>


                  <Grid xs={12}>
                  <Typography variant='h5' my={2}>
                      Skills
                  </Typography>
                  </Grid>

                  <Grid container spacing={1}>
                      {skills.map((skill, index) => (
                          <Grid item key={index}>
                          <Chip label={skill} variant="outlined" />
                          </Grid>
                      ))}
                      <Grid item xs={10}my={2} mx="auto">

                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            onClick={() => handleAddToProfile(skills)}
                          >
                          <AddCircleOutlineIcon sx={{ mr: 1 }}/>  Add Skills to my Profile
                        </Button>
                      </Grid>
                  </Grid>
              
                  {(collegeName || degree) && (
                  <Grid item xs={12} sm={6}>
                      <ListItem>
                      <ListItemIcon>
                          <SchoolIcon />
                      </ListItemIcon>
                      <ListItemText primary={`${collegeName}, ${degree}`} />
                      </ListItem>
                  </Grid>
                  )}

                  {designation && (
                  <Grid item xs={12} sm={6}>
                      <ListItem>
                      <ListItemIcon>
                          <WorkIcon />
                      </ListItemIcon>
                      <ListItemText primary={designation} />
                      </ListItem>
                  </Grid>
                  )}

                  {experience && (
                  <Grid item xs={12}>
                      <Box fullWidth p={2}>
                    
                      <Typography variant="h6" mb={2}>
                      Experience: 
                      </Typography>
    
                      <Card >
                          {experience.map((exp, index) => (
                          <ListItem key={index}>
                              <ListItemText primary={exp} />
                          </ListItem>
                          ))}
                      </Card>
                      </Box>
                  </Grid>
                  )}

                  {companyNames && (
                  <Grid item xs={12}>
                      <ListItem>
                      <ListItemIcon>
                          <WorkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Companies" />
                      <List>
                          {companyNames.map((company, index) => (
                          <ListItem key={index}>
                              <ListItemText primary={company} />
                          </ListItem>
                          ))}
                      </List>
                      </ListItem>
                  </Grid>
                  )}


                  <Grid item xs={12} sm={6}>
                  <ListItem>
                      <ListItemIcon>
                      <TimelineIcon />
                      </ListItemIcon>
                      <ListItemText primary={`Total Experience: ${totalExperience} years`} />
                  </ListItem>
                  </Grid>
              </Grid>
          </List>
        </Box>
        )}
      </Box>
      </Container>
      </>
    );
};

export default ResumeExtractor;

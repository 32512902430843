// CustomButton.js

import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
const StyledButton = styled(Button)(({ backgroundColor }) => ({
  background: "#272260",
  border: "0.9px solid #272260",
  borderRadius: 3,
  color: "#FFFFFF",
  height: "54px",
  padding: "0 30px",
  margin: "auto",
  height: "43px",
  borderRadius: "4px",
  marginTop: "20px",
  width: "100%",
  display: "flex",
  marginBottom: "10px",
  fontSize: "20px",
  fontWeight: 400,
  fontFamily:"sans-serif",
  textTransform:"none",
  "&:hover": {
    backgroundColor: backgroundColor || "#272260", 
  },
}));

const CustomButton = ({ onClick, children, hideIcon, backgroundColor }) => {
  return (
    <StyledButton variant="contained" onClick={onClick} backgroundColor={backgroundColor}>
      {/* {!hideIcon && <AddOutlinedIcon sx={{ fontSize: "16px" }} />} */}
      {children}
    </StyledButton>
  );
};

export default CustomButton;

import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import { Grid, Card, Box, Typography, Container } from "@mui/material";
import {
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FacebookIcon, LinkedinIcon, EmailIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { FaFilePdf } from 'react-icons/fa';

import instance from "../../globals/axios";
import ProfileHeader from "../../components/ProfileHeader";
import CustomButton from "../../components/Button/CustomButtom";
import BackButton from "../../components/Button/BackButton";
import Sidebar from "../../components/Sidebar";

export default function Skills() {
  // const shareUrl = 'https://github.com/AtharvaUtekar'; // Replace with your user's profile URL
  const [shareUrl, setShareUrl] = useState("");

  useEffect(() => {
    const iveri_id = localStorage.getItem("iveri_id");
    if (iveri_id) setShareUrl(`https://main.iveri.newrl.net/iveri-profile/${iveri_id}`);
  }, []);
  const title = "Check out my profile";
  const pdfUrl = 'path/to/your/file.pdf'; 
  return (
    <>
    <Sidebar />
      <Container maxWidth="xs" style={{ marginTop: "20px" }}>
        <ProfileHeader showUploadIcon={false} />

        <Box>
          <Typography sx={{fontWeight: "600"}} py={2} variant="h5" gutterBottom>
            Share My Profile
          </Typography>
          <Card
  sx={{
    p: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'grey.400',
    boxShadow: 'none'
  }}
>
  <Grid container spacing={2} justifyContent="center" alignItems="center">
    <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
      <FacebookShareButton url={shareUrl} quote={title}>
        <FacebookIcon size={48} round />
      </FacebookShareButton>
      <Typography variant="caption">Facebook</Typography>
    </Grid>
    <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
      <LinkedinShareButton url={shareUrl} title={title}>
        <LinkedinIcon size={48} round />
      </LinkedinShareButton>
      <Typography variant="caption">LinkedIn</Typography>
    </Grid>
    <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
      <EmailShareButton url={shareUrl} subject={title}>
        <EmailIcon size={48} round />
      </EmailShareButton>
      <Typography variant="caption">Email</Typography>
    </Grid>
    <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
      <TwitterShareButton url={shareUrl} title={title}>
        <TwitterIcon size={48} round />
      </TwitterShareButton>
      <Typography variant="caption">Twitter</Typography>
    </Grid>
    <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
      <WhatsappShareButton url={shareUrl} title={title}>
        <WhatsappIcon size={48} round />
      </WhatsappShareButton>
      <Typography variant="caption">WhatsApp</Typography>
    </Grid>
    <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
      <a href={pdfUrl} download>
        <FaFilePdf size={48} color="#E94E77" /> {/* PDF icon */}
      </a>
      <Typography variant="caption">PDF</Typography>
    </Grid>
  </Grid>
</Card>
          <CustomButton my={1}>{"Next"}</CustomButton>
          <BackButton/>
        </Box>
      </Container>
    </>
  );
}

import React, { useState } from 'react'
import { Button, TextField, Container, Grid, Typography } from '@mui/material';
import logo from '../../src/assets/iveri.png'
import { useHistory } from "react-router-dom";
import instance from '../globals/axios';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ForgotPasswordPic from "../assets/forgot.png"

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [linkSent, setLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await instance.post('/user/forgot-password/', { email });
      if (response.status === 200) {
        setLinkSent(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component='main' fullWidth sx={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'space-between' }}>
      <Grid container mt="10%" justifyContent="center">
        <Grid item xs={12} md={5}>
        <ArrowBackIosIcon
              onClick={handleBack}
              sx={{ position: 'absolute', top: 25, left: 10, border: "2px solid black", borderRadius: "100%", padding: "1px", paddingLeft: "6px", color: "black", height: "15px", width: "11px" }}
            />
          <Grid flex xs={6} sx={{ mx: "auto", position: 'relative' }}>
            <img alt='iVeri logo' src={logo} style={{ width: "100%" }} />
          </Grid>
          <Typography sx={{ fontSize: "30px", fontWeight: "600", pt: 2 }}>Forgot Password</Typography>
          <Grid container justifyContent="center" my={10}>
            <img alt='Forgot Password' src={ForgotPasswordPic} style={{ width: '100px', height: 'auto' }} />
          </Grid>
          <form noValidate onSubmit={handleSubmit}>
            <Grid container mt={2}>
              <Typography sx={{ color: "#596574", fontSize: "10px", py: 2 }}>Enter Email ID or Phone no. for Forgot Password</Typography>
              <Typography sx={{ fontSize: "16px" }}>Email or Phone no.</Typography>
              <TextField
                fullWidth
                type="email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  borderRadius: "5px",
                  height: "45px",
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                    '& input': {
                      padding: '10px 14px',
                    },
                  },
                }}
              />
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" mt={4} mb={2}>
        <Button type='submit' sx={{ width: "100%", backgroundColor: "#272260", color: "white", height: "50px", textTransform: "None", fontSize: "1em" }} variant={linkSent ? 'outlined' : 'contained'} color={linkSent ? 'success' : 'primary'} disabled={loading}>
          {loading ? 'Sending...' : (linkSent ? 'Reset Link Sent' : 'Send Reset Link')}
        </Button>
      </Grid>
    </Container>
  )
}

export default ForgotPassword

import React, { useState } from 'react';
import { Container, Card, CardContent, Grid, Typography, Box, Button, IconButton } from '@mui/material';
import Sidebar from '../../components/Sidebar';
import MyButtonGroup from '../../components/MyButtonGroup.js';
import { FaFlag } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

const ViewJobs = () => {
  const [flaggedJobs, setFlaggedJobs] = useState({});
  const history = useHistory();

  const handleFlagClick = (index, e) => {
    e.stopPropagation();
    setFlaggedJobs((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleCardClick = (id) => {
    history.push(`/job/${id}`);
  };

  const jobData = [
    {
      id: 1,
      Designation: "Web Developer",
      company: "ASQI",
      location: "Mumbai, Maharashtra",
      salary: "$15k - $25k",
      postedTime: "1 Month ago",
      profile: "Commercial Manager, 10-12 Years",
      qualification: "Experience in managing large projects...",
      skills: "Leadership, Communication, Finance",
      Description: "Create and maintain responsive, user-centric websites with a focus on front-end design, performance optimization, and seamless user experience."
    },
    {
      id: 2,  // Changed id to 2 to make it unique
      Designation: "Software Engineer",
      company: "iveri",
      location: "Mumbai, Maharashtra",
      salary: "$10k - $15k",
      postedTime: "1 Month ago",
      profile: "Commercial Manager, 10-12 Years",
      qualification: "Experience in managing large projects...",
      skills: "Leadership, Communication, Finance",
      Description: "Design, develop, test, and deploy scalable software solutions, emphasizing problem-solving, code efficiency, and collaboration within cross-functional teams."
    },
  ];

  return (
    <div>
      <Sidebar />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
        <MyButtonGroup />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button variant="contained" sx={{ backgroundColor: "#272260", fontSize: "10px", width: "150px", height: "50px" }}>
          Find Job
        </Button>
      </Box>
      <Grid mt={3} ml={1}>
        <Typography sx={{ fontSize: '20px' }}>Job Feed</Typography>
      </Grid>
      <Container sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          {jobData.map((job) => (
            <Grid item xs={12} key={job.id} onClick={() => handleCardClick(job.id)}>
              <Card sx={{
                border: '1px solid rgba(0,0,0, 0.1)',
                boxShadow: "none",
                cursor: 'pointer',
              }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                      <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                        {job.Designation}
                      </Typography>
                      <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                        {job.company}
                      </Typography>
                      <Typography variant="body2" component="p">
                        {job.Description}
                      </Typography>
                    </Box>
                    <IconButton onClick={(e) => handleFlagClick(job.id, e)}>
                      <FaFlag color={flaggedJobs[job.id] ? 'black' : 'grey'} />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default ViewJobs;
